/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
"use strict";

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the heliApp
 */
angular.module("heliApp").controller("MainCtrl", [
  "$scope",
  "$state",
  "$rootScope",
  "$window",
  "userRegisterService",
  "$timeout",
  "$interval",
  "REQUEST_URL",
  "operatorService",
  "MESSAGE_HELPER",
  "$cookies",
  "userAdventureDetailService",
  "sharedService",
  "parallaxHelper",
  "$document",
  "userLoginService",
  "publicFeedsService",
  "CONST_HELPER",
  "$stateParams",
  "$filter",
  "operatorProfileService",
  "$location",
  "userSocialProfileService",
  "agentService",
  "$q",
  "rootScopeService",
  "CURRENT_HOST",
  "IPAPI_KEY",
  function (
    $scope,
    $state,
    $rootScope,
    $window,
    userRegisterService,
    $timeout,
    $interval,
    REQUEST_URL,
    operatorService,
    MESSAGE_HELPER,
    $cookies,
    userAdventureDetailService,
    sharedService,
    parallaxHelper,
    $document,
    userLoginService,
    publicFeedsService,
    CONST_HELPER,
    $stateParams,
    $filter,
    operatorProfileService,
    $location,
    userSocialProfileService,
    agentService,
    $q,
    rootScopeService,
    CURRENT_HOST,
    IPAPI_KEY
  ) {
    $scope.MESSAGE_HELPER = MESSAGE_HELPER;
    $scope.CONST_HELPER = CONST_HELPER;
    $rootScope.packageList = [];
    $rootScope.pageDetails = {};
    $rootScope.footer = {}
    $rootScope.blankPage = false;
    $scope.loader = false;
    $scope.hasProfile = false;
    rootScopeService.setValue("hasProfile", $scope.hasProfile);
    $scope.userLoggedIn = false;
    $rootScope.fromSocial = false;
    $rootScope.accountMergeStatus = true;
    $rootScope.forgotPasswordSuccess = false;
    $rootScope.userRole = "user";
    rootScopeService.setValue("userRole", $rootScope.userRole);
    $scope.countryName = [];
    $scope.changePasswordErr = false;
    $scope.changePasswordServerErr = false;
    $scope.oldPasswordErr = false;
    $scope.changePasswordModel = {};
    $scope.changePwdServerErrMessage = {};
    $scope.oldPassNotMatch = false;
    $scope.changeConfirmPassErr = false;
    $scope.isSuccessfull = false;
    $rootScope.showFooter = false;
    rootScopeService.setValue("showFooter", $rootScope.showFooter);
    $scope.hideSearchIcon = false;
    rootScopeService.setValue("hideSearchIcon", $rootScope.showFooter);
    $rootScope.feedsCount = CONST_HELPER.ZERO;
    rootScopeService.setValue("feedsCount", $rootScope.feedsCount);
    $scope.mnth = "";
    $scope.yr = "";
    $scope.mail = {};
    $scope.sendToEmail = [];
    $scope.recipients = [];
    $rootScope.following_scrollbarId = {};
    $rootScope.follower_scrollbarId = {};
    $rootScope.operator_scrollbarId = {};
    $rootScope.user_scrollbarId = {};
    $scope.playHomeVideo = false;
    $rootScope.featuredAdventures = [];
    $rootScope.featuredAmbassadors = [];
    $rootScope.featuredActivity = [];
    $scope.agentAllOperators = [];
    $scope.showErrorSamePassword = false;
    $scope.copyrightYear = new Date();
    $rootScope.currencyAlreadyFounded = false;
    $rootScope.referalProgrammInfo = true;

    var allowedFullPageStates = [
      "home",
      "new-route-white-label",
      "adventure-detail",
      "adventure-detail-private",
      "package-slot-addons-summary",
      "secure-checkout",
      "guest-details-summary",
      "waitlist.**",
      "waitlist",
    ];
    var allowedAgentURLs = [
      "new-route-agent",
      "agent-signup",
      "signup",
      "signin",
      "agent-calendar",
      "agent-analytics",
      "agent-booking-list",
      "agent-operators",
      "agent-adventures",
      "resetPassword",
      "forgotPassword",
      "operator-manual-booking",
      "operator-edit-slots",
      "adventure-detail",
    ];
    $rootScope.HomePagelogo = {
      operatorProfile: [],
      mediaLogo: [],
      partnerLogo: [],
    };
    $scope.globalEmailPattern =
      /^([a-zA-Z0-9_.-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
    $scope.dateRange = {
      startDate: null,
      endDate: null,
    };
    $scope.locationVal = "";
    var listShown = CONST_HELPER.LIST.SHOWN;
    var listSize = CONST_HELPER.LIST.SIZE;
    $scope.noSendEmail = false;
    $scope.errEmailFormat = false;
    $scope.successMsg = "Your post has been updated successfully.";
    rootScopeService.setValue("successMsg", $scope.successMsg);
    $scope.user = {};
    $rootScope.userProfile = {};
    $rootScope.isToolTipClick = true;
    $rootScope.isCustomCalendarClick = true;
    $rootScope.ambassador_list_state = {};
    $rootScope.errmsg = "Please select a valid location";
    $scope.isCustomCalendarFilter = false;
    $scope.form = {};
    $rootScope.tag = {};
    $rootScope.referral_id = null;
    $scope.origin = window.origin || window.location.origin;
    $rootScope.initializePhoneField = function (field) {
      $(field).intlTelInput({
        nationalMode: false,
        preferredCountries: ["us"],
      });
      $(field).on("input", function () {
        $(this).val(
          $(this)
            .val()
            .replace(/[^+\d]/g, "")
        );
      });
    };
    // var twitter
    $rootScope.closeWhiteLabelIFrame = function () {
      $window.parent.closeModal();
    };
    $rootScope.setWhiteLabelPreviousState = function () {
      $window.history.back();
    };
    var operatorSideMenuPage = [
      "operator-edit-slots",
      "operator-manual-booking",
      "adventure-detail",
      "adventure-detail-private",
    ];
    var agentSideMenuPage = ["operator-edit-slots", "operator-manual-booking"];
    $scope.footerDisplay = function () {
      // footer show and hide conditions
      var states = [
        "signin",
        "signup",
        "operator-signin",
        "operator-setPassword",
        "createAdventure",
        "emailVerification",
        "resetPassword",
        "forgotPassword",
        "link-expire",
        "mergeAccountSuccess",
      ];
      var operatorStates = ["operator-edit-profile", "operator-package-detail"];
      if (
        states.indexOf($scope.currentState.name) > -1 ||
        ($rootScope.userRole === "operator" &&
          operatorStates.indexOf($scope.currentState.name) === -1)
      ) {
        $rootScope.showFooter = false; // hide footer
        rootScopeService.setValue("showFooter", $rootScope.showFooter);
      } else {
        $rootScope.showFooter = true; // show footer
        rootScopeService.setValue("showFooter", $rootScope.showFooter);
      }
    };
    $scope.logoutWithOutRedirection = function () {
      userLoginService.logOutUser($scope.user.token).then(
        function (response) {
          var cookies = $cookies.getAll();
          var couponData = {
            promo_code_id: $cookies.get("coupon_id"),
            cart_id: $cookies.get("cart_id"),
            previousPrice: "", // static cart total.
          };
          angular.forEach(cookies, function (v, k) {
            $cookies.remove(k);
          }); // clear local storage
          // var domainName = window.location.host.substr(window.location.host.indexOf('.'));
          (function () {
            var cookies = document.cookie.split("; ");
            for (var c = 0; c < cookies.length; c++) {
              var d = window.location.hostname.split(".");
              while (d.length > 0) {
                var cookieBase =
                  encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
                  "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
                  d.join(".") +
                  " ;path=";
                var p = location.pathname.split("/");
                document.cookie = cookieBase + "/";
                while (p.length > 0) {
                  document.cookie = cookieBase + p.join("/");
                  p.pop();
                }
                d.shift();
              }
            }
          })();
          $scope.userLoggedIn = false; // rest scope
          $scope.hasProfile = false; // rest scope
          rootScopeService.setValue("hasProfile", $scope.hasProfile);
          $scope.getUserDetails(); // reset scope
          $rootScope.userRole = "user";
          rootScopeService.setValue("userRole", $rootScope.userRole);
          $rootScope.myCartLength = CONST_HELPER.ZERO;
          rootScopeService.setValue("myCartLength", $rootScope.myCartLength);
          $scope.user = null;
          // dont redirect user to home page...
          var currentState = $rootScope.currentState;
          if (
            currentState.name === "filter" ||
            currentState.name === "adventure-detail"
          ) {
            // do nothing when user logout...
            $rootScope.$broadcast("user.logout", couponData);
          } else {
            $rootScope.$broadcast("user.logout", couponData);
            if (!$scope.siteInIFrame) {
              $state.go("home");
            } // redirect to home page
          }
          getUserIP();
          closeNav();
        },
        function () {}
      );
    };
    $scope.logout = function () {
      // logout user from heli
      userLoginService.logOutUser($scope.user.token).then(
        function (response) {
          var cookies = $cookies.getAll();
          var couponData = {
            promo_code_id: $cookies.get("coupon_id"),
            cart_id: $cookies.get("cart_id"),
            previousPrice: "", // static cart total.
          };
          angular.forEach(cookies, function (v, k) {
            $cookies.remove(k);
          }); // clear local storage
          // var domainName = window.location.host.substr(window.location.host.indexOf('.'));
          (function () {
            var cookies = document.cookie.split("; ");
            for (var c = 0; c < cookies.length; c++) {
              var d = window.location.hostname.split(".");
              while (d.length > 0) {
                var cookieBase =
                  encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
                  "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
                  d.join(".") +
                  " ;path=";
                var p = location.pathname.split("/");
                document.cookie = cookieBase + "/";
                while (p.length > 0) {
                  document.cookie = cookieBase + p.join("/");
                  p.pop();
                }
                d.shift();
              }
            }
          })();
          $scope.userLoggedIn = false; // rest scope
          $scope.hasProfile = false; // rest scope
          rootScopeService.setValue("hasProfile", $scope.hasProfile);
          $scope.getUserDetails(); // reset scope
          $rootScope.userRole = "user";
          rootScopeService.setValue("userRole", $rootScope.userRole);
          $rootScope.myCartLength = CONST_HELPER.ZERO;
          rootScopeService.setValue("myCartLength", $rootScope.myCartLength);
          $scope.user = {};
          rootScopeService.setValue("user", $scope.user);
          // dont redirect user to home page...
          var currentState = $rootScope.currentState;
          if (
            currentState.name === "filter" ||
            currentState.name === "adventure-detail"
          ) {
            // do nothing when user logout...
            $rootScope.$broadcast("user.logout", couponData);
          } else {
            $rootScope.$broadcast("user.logout", couponData);
            if (!$scope.siteInIFrame) {
              $state.go("home");
            } // redirect to home page
            if ($rootScope.isAgent) {
              $state.go("signin");
            }
          }
          getUserIP();
          closeNav();
        },
        function () {}
      );
    };

    $rootScope.manualLogout = function () {
      var user = $cookies.get("user");
      if (user) {
        user = JSON.parse(user);
        if (user.role_id == "5") {
          // if user is an operator
          if (!$rootScope.isAgent) {
            $scope.logoutWithOutRedirection();
          }
        } else {
          if ($rootScope.isAgent) {
            $scope.logoutWithOutRedirection();
          }
        }
      }
    };

    $rootScope.$on("state-change", function (to, from) {
      $scope.getUserDetails(); // set user details in scope
      $scope.footerDisplay();
      $rootScope.activityFilterPage = false;
      rootScopeService.setValue(
        "activityFilterPage",
        $rootScope.activityFilterPage
      );
      // manualLogout();
      // current state is home and user logged in is operator then redirect to dashboard...
      if (
        $rootScope.currentState.name === "home" &&
        $scope.user.role_id === 3
      ) {
        $timeout(function () {
          $state.go("operator-dashboard");
        });
      }
      if (
        $rootScope.isAgent &&
        $rootScope.currentState.name === "home" &&
        $scope.user.role_id === 5
      ) {
        $timeout(function () {
          $state.go("agent-calendar");
        });
      }
      if ($rootScope.currentState.name !== "order-successful") {
        if ($cookies.get("orderDetails")) {
          $cookies.remove("orderDetails"); // remove order details from cookie
        }
      }
      if ($rootScope.currentState.name === "filter") {
        $scope.hideSearchIcon = false;
        rootScopeService.setValue("hideSearchIcon", $rootScope.showFooter);
      } else {
        $scope.hideSearchIcon = true;
        rootScopeService.setValue("hideSearchIcon", $rootScope.showFooter);
        $rootScope.tag = {
          keyword: "",
        };
      }

      if (
        $rootScope.currentState.name !== "myprofile" &&
        $rootScope.currentState.name !== "adventure-detail"
      ) {
        $cookies.put("appliedActivityFilter", "");
        $cookies.put("filterObject", "");
        $cookies.put("abilityApplied", "");
        $cookies.put("searchedActivity", "");
      }

      if (
        $rootScope.currentState.name !== "myprofile" &&
        $rootScope.currentState.name !== "follower-listing" &&
        $rootScope.currentState.name !== "following-listing"
      ) {
        $rootScope.followingListingOffset = CONST_HELPER.ZERO;
        $rootScope.followerListingOffset = CONST_HELPER.ZERO;
      }

      if (
        $rootScope.isSubDomain &&
        !allowedFullPageStates.includes($rootScope.currentState.name)
      ) {
        if (typeof $rootScope.isAgent === "undefined") {
          // CALLL API
          if (username === "agents") {
            $rootScope.isAgent = true;
            rootScopeService.setValue("isAgent", $rootScope.isAgent);
            // $state.go('new-route-agent');
          } else {
            var isNotExternalDomain =
              $location.host().indexOf("heli-dev.kiwireader.com") > -1 ||
              $location.host().indexOf("heli-qa.kiwireader.com") > -1 ||
              $location.host().indexOf("heli-staging.kiwireader.com") > -1 ||
              $location.host().indexOf("heli.life") > -1;
            var requestObject = {};
            if (isNotExternalDomain) {
              requestObject = {
                username: username,
              };
            } else {
              requestObject = {
                username: username,
                sub_domain_url: $location.protocol() + "://" + $location.host(),
              };
            }
            userSocialProfileService
              .getUserSocialProfile(requestObject, "")
              .then(
                function (response) {
                  if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
                    var userProfile = response.data.data;
                    if (userProfile.role_id == 3) {
                      $rootScope.isSubDomain = true;
                      $rootScope.siteInIFrame = true;
                      rootScopeService.setValue(
                        "siteInFrame",
                        $rootScope.siteInIFrame
                      );
                      if ($rootScope.currentState.name !== "user-orders") {
                        $location.path("/");
                      }
                    } else if (userProfile.role_id == 5) {
                      // ----todo-------
                      $rootScope.isAgent = true;
                      rootScopeService.setValue("isAgent", $rootScope.isAgent);
                      $rootScope.isSubDomain = false;
                      $rootScope.siteInIFrame = false;
                      rootScopeService.setValue(
                        "siteInFrame",
                        $rootScope.siteInIFrame
                      );
                    }
                  }
                },
                function () {}
              );
          }
        } else {
          if (!$rootScope.isAgent) {
            $location.path("/");
          }
        }
      }
      if (
        $rootScope.isAgent &&
        !allowedAgentURLs.includes($rootScope.currentState.name)
      ) {
        $state.go("new-route-agent");
      }
      $rootScope.selectedActivity = "";
      rootScopeService.setValue(
        "selectedActivity",
        $rootScope.selectedActivity
      );
      $scope.visiterSubscribed = false;
      document.getElementsByTagName("body")[0].classList.remove("modal-open");
      // eslint-disable-next-line no-undef
      $("div[class*=modal-backdrop]").remove();

      // eslint-disable-next-line no-undef
      var wrapper = angular.element(document.getElementById("wrapper"));
      if ($rootScope.currentState.name !== "home" && wrapper) {
        wrapper[0].classList.remove("fix-footer");
      } else {
        wrapper[0].classList.add("fix-footer");
      }

      // eslint-disable-next-line no-undef
      ga("heliGAtracker.set", "page", $rootScope.currentState.templateUrl);
      ga("heliGAtracker.send", "pageview");
      // watch for rootscope isagent changes
      $scope.$watch("$root.isAgent", function () {
        $scope.userPLevel = $rootScope.isAgent;
        if (
          !$rootScope.isAgent &&
          operatorSideMenuPage.indexOf($rootScope.currentState.name) === -1 &&
          $rootScope.screenWidth > CONST_HELPER.SCREEN.DESKTOP_WIDTH
        ) {
          $rootScope.hideSideMenu = true;
          rootScopeService.setValue("hideSideMenu", $rootScope.hideSideMenu);
        } else {
          $rootScope.hideSideMenu = false;
          rootScopeService.setValue("hideSideMenu", $rootScope.hideSideMenu);
          // eslint-disable-next-line no-unused-expressions
          document.getElementsByClassName("close-option").length
            ? (document.getElementsByClassName(
                "close-option"
              )[0].style.display = "none")
            : "";
          // document.getElementsByClassName('open-option').length ? document.getElementsByClassName('open-option')[0].style.display = 'block' : '';
        }
        if (
          $rootScope.isAgent &&
          agentSideMenuPage.indexOf($rootScope.currentState.name) === -1 &&
          $rootScope.screenWidth > CONST_HELPER.SCREEN.DESKTOP_WIDTH
        ) {
          $rootScope.hideSideMenu = true;
          rootScopeService.setValue("hideSideMenu", $rootScope.hideSideMenu);
        } else {
          $rootScope.hideSideMenu = false;
          rootScopeService.setValue("hideSideMenu", $rootScope.hideSideMenu);
          // eslint-disable-next-line no-unused-expressions
          document.getElementsByClassName("close-option").length
            ? (document.getElementsByClassName(
                "close-option"
              )[0].style.display = "none")
            : "";
          // document.getElementsByClassName('open-option').length ? document.getElementsByClassName('open-option')[0].style.display = 'block' : '';
        }
      });
      if (
        !$rootScope.isAgent &&
        operatorSideMenuPage.indexOf($rootScope.currentState.name) === -1 &&
        $rootScope.screenWidth > CONST_HELPER.SCREEN.DESKTOP_WIDTH
      ) {
        $rootScope.hideSideMenu = true;
        rootScopeService.setValue("hideSideMenu", $rootScope.hideSideMenu);
      } else {
        $rootScope.hideSideMenu = false;
        rootScopeService.setValue("hideSideMenu", $rootScope.hideSideMenu);
        // eslint-disable-next-line no-unused-expressions
        document.getElementsByClassName("close-option").length
          ? (document.getElementsByClassName("close-option")[0].style.display =
              "none")
          : "";
        // document.getElementsByClassName('open-option').length ? document.getElementsByClassName('open-option')[0].style.display = 'block' : '';
      }
      if (
        $rootScope.isAgent &&
        agentSideMenuPage.indexOf($rootScope.currentState.name) === -1 &&
        $rootScope.screenWidth > CONST_HELPER.SCREEN.DESKTOP_WIDTH
      ) {
        $rootScope.hideSideMenu = true;
        rootScopeService.setValue("hideSideMenu", $rootScope.hideSideMenu);
      } else {
        $rootScope.hideSideMenu = false;
        rootScopeService.setValue("hideSideMenu", $rootScope.hideSideMenu);
        // eslint-disable-next-line no-unused-expressions
        document.getElementsByClassName("close-option").length
          ? (document.getElementsByClassName("close-option")[0].style.display =
              "none")
          : "";
        // document.getElementsByClassName('open-option').length ? document.getElementsByClassName('open-option')[0].style.display = 'block' : '';
      }
      // reset calendar month and view type if user move anywhere else...
      if (
        $rootScope.currentState.name !== "operator-edit-slots" &&
        $rootScope.currentState.name !== "operator-calendar"
      ) {
        $rootScope.currentMonthDate = "";
        $rootScope.calendarViewType = "month";
      }

      closeNav();
      // $rootScope.closeMyNav();
    });
    $scope.$on("$viewContentLoaded", function () {
      if (
        $rootScope.currentState &&
        $rootScope.currentState.name === "home" &&
        !$rootScope.isAgent
      ) {
        $scope.showFullHeader = true;
        rootScopeService.setValue("showFullHeader", $scope.showFullHeader);
        $(".text-fade, .text-fade2").css("opacity", "1");
        $(".text-fade").textillate({
          minDisplayTime: 300,
        });
        $(".text-fade2").textillate({
          minDisplayTime: 100,
        });
      } else {
        $scope.showFullHeader = false;
        rootScopeService.setValue("showFullHeader", $scope.showFullHeader);
      }
    });
    $scope.$on("serverError", function () {
      /* page not found - 500 error */
    });
    $scope.$on("invalidAuthToken", function () {
      /* page not found - 401,403 error */
    });
    $scope.$on("invalidCredentials", function () {
      /* page not found - 400 error */
    });
    $scope.$on("recordNotFound", function () {
      /* page not found - 404 error */
    });

    $scope.refreshResults = function ($select) {
      if ($rootScope.currentState.name !== "filter") {
        console.log($select.items);
      }
    };

    $scope.updatePackageCount = function (count) {
      // update package count
      var user = "";
      if ($cookies.get("user")) {
        // check for user existence
        user = JSON.parse($cookies.get("user"));
        user.package_count = count; // update package count
        $scope.hostName = $location.host();
        if ($scope.hostName != "localhost") {
          var domainName = window.location.host.substr(
            window.location.host.indexOf(".")
          );
          var productionDomain = "heli.life";
          if (window.location.host == productionDomain) {
            $cookies.put("user", JSON.stringify(user), {
              domain: productionDomain,
            });
          } else {
            // $cookies.put('user', JSON.stringify(user), {
            //   domain: domainName
            // });
            $cookies.put("user", JSON.stringify(user));
          }
        } else {
          $cookies.put("user", JSON.stringify(user));
        }
        $scope.user = user; // update scope
        $rootScope.user = user;
        rootScopeService.setValue("user", $rootScope.user);
      }
    };
    $scope.setUserDetails = function (user, from, getClientIP) {
      // set user details in the scope
      var token = "";
      var userData = $cookies.get("user");
      if (userData && JSON.parse(userData).id === user.id) {
        // for same user only
        token = JSON.parse(userData).token;
        if (token && !user.token) {
          user.token = token;
        }
      }
      $scope.hostName = $location.host();
      if ($scope.hostName != "localhost") {
        var domainName = CURRENT_HOST;
        var productionDomain = "heli.life";
        if (window.location.host == productionDomain) {
          $cookies.put("user", JSON.stringify(user), {
            domain: productionDomain,
          });
        } else {
          // $cookies.put('user', JSON.stringify(user), {
          //   domain: domainName
          // });
          $cookies.put("user", JSON.stringify(user));
        }
      } else {
        $cookies.put("user", JSON.stringify(user));
      }

      $scope.user = user;
      $rootScope.user = user;
      rootScopeService.setValue("user", $rootScope.user);
      if ($scope.user.role_id === 3) {
        $rootScope.userRole = "operator";
        rootScopeService.setValue("userRole", $rootScope.userRole);
      } else if ($scope.user.role_id === 5) {
        $rootScope.userRole = "agent";
        rootScopeService.setValue("userRole", $rootScope.userRole);
      } else {
        $rootScope.userRole = "user";
        rootScopeService.setValue("userRole", $rootScope.userRole);
        if (!$rootScope.siteInIFrame) {
          getUserCartListCount(user.token);
        }
        if (!getClientIP && !$rootScope.siteInIFrame) {
          getUserIP();
        }
      }
      if (from === "second") {
        // only for logged in or full profile user
        $scope.userLoggedIn = true;
        $cookies.put("hasProfile", true);
        $scope.hasProfile = true;
        rootScopeService.setValue("hasProfile", $scope.hasProfile);
      } else if (from === "first") {
        $cookies.put("hasProfile", false);
        $scope.hasProfile = false;
        rootScopeService.setValue("hasProfile", $scope.hasProfile);
      }
      $('[data-toggle="tooltip1"]').tooltip("");
    };

    $scope.getUserDetails = function () {
      // get user details  from localstorage
      if ($cookies.get("user")) {
        // if user details exist
        $scope.user = JSON.parse($cookies.get("user"));
        if ($scope.user.role_id === 3 && !$scope.user.operator_policy) {
          $scope.getOperatorProfileDetail(false);
        }
        $rootScope.user = $scope.user;
        rootScopeService.setValue("user", $rootScope.user);
        $scope.userLoggedIn = true; // set the scope
        if ($scope.user.role_id === 3) {
          $rootScope.userRole = "operator";
          rootScopeService.setValue("userRole", $rootScope.userRole);
          $rootScope.calendarAgentOperator = {};
        } else if ($scope.user.role_id === 5) {
          $rootScope.userRole = "agent";
          rootScopeService.setValue("userRole", $rootScope.userRole);
          getAgentOperators(); // fetch the all active operators of agent.
        } else {
          $rootScope.userRole = "user";
          rootScopeService.setValue("userRole", $rootScope.userRole);
        }
        if ($cookies.get("hasProfile") === "true") {
          // check for profile completion
          $scope.hasProfile = true;
          rootScopeService.setValue("hasProfile", $scope.hasProfile);
        } else if ($cookies.get("hasProfile") === "false") {
          $scope.hasProfile = false;
          rootScopeService.setValue("hasProfile", $scope.hasProfile);
        }
      } else {
        $scope.userLoggedIn = false; // user not logged in
        $scope.hasProfile = false;
        rootScopeService.setValue("hasProfile", $scope.hasProfile);
        $scope.user = {};
      }
    };
    // call when webiste isloaded...
    $timeout(function () {
      if ($scope.user.role_id !== 3) {
        var token = $scope.user ? $scope.user.token : "";
        if (!$rootScope.siteInIFrame) {
          // do not call cartcount api over White label...
          getUserCartListCount(token);
        }
        getUserIP();
      } else {
        $scope.getOperatorProfileDetail();
      }
    });

    // function to get the operators of agent...
    function getAgentOperators() {
      // if ($scope.agentAllOperators.length) {
      //   return;
      // }
      var requestObject = {
        status: CONST_HELPER.AGENT_REQUEST_STATUS.ACCEPT,
      };
      agentService
        .getAgentOperators(requestObject, $scope.user.token)
        .then(function (response) {
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            $scope.agentAllOperators = response.data.data;
            if ($rootScope.currentState.name !== "agent-calendar") {
              var defaultObj = {
                name: "All Operators",
                id: "",
              };
              $scope.agentAllOperators.splice(0, 0, defaultObj);
              $scope.agentOperator = defaultObj;
            } else {
              $rootScope.calendarAgentOperator =
                $rootScope.calendarAgentOperator || $scope.agentAllOperators[0];
            }
            $scope.$broadcast("setSelectedOperator");
          } else if (
            response.data.code === CONST_HELPER.API_RESPONSE.NOT_FOUND
          ) {
            $scope.agentAllOperators = [];
          }
        });
    }

    // function to get the operator details
    $scope.getOperatorProfileDetail = function (setNewDetails = true) {
      operatorProfileService
        .getOperatorProfile($scope.user.token)
        .then(function (response) {
          if (!response || response.data.code !== CONST_HELPER.API_RESPONSE.OK)
            return;
          var userData = JSON.parse($cookies.get("user"));
          var responseData = response.data.data;
          userData.operator_commissions = responseData.operator_commissions;
          userData.location_exists = responseData.location_exists;
          userData.on_my_site = responseData.operator_profile.on_my_site; // On my site option status
          userData.operator_policy = responseData.operator_policy;
          setNewDetails
            ? $scope.setUserDetails(userData)
            : ($scope.user = userData);
          if (
            $scope.currentState.name === "on-my-site" &&
            !userData.on_my_site
          ) {
            // if on profile filling page stay there
            $state.go("operator-dashboard");
          } else if ($scope.currentState.name === "home") {
            $state.go("operator-dashboard");
          }
        });
    };

    // load a script and append to body
    $scope.loadScript = function (url, callback) {
      var script = document.createElement("script");
      script.type = "text/javascript";

      if (script.readyState) {
        // IE
        script.onreadystatechange = function () {
          if (
            script.readyState === "loaded" ||
            script.readyState === "complete"
          ) {
            script.onreadystatechange = null;
            callback();
          }
        };
      } else {
        // Others
        script.onload = function () {
          callback();
        };
      }
      script.src = url;
      document.getElementById("navigation-scroll").appendChild(script);
    };

    // for hosted site...
    $scope.inIframe = function () {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    };
    if ($rootScope.isSubDomain) {
      $rootScope.siteInIFrame = true;
      rootScopeService.setValue("siteInFrame", $rootScope.siteInIFrame);
    } else {
      $rootScope.siteInIFrame = $scope.inIframe();
      rootScopeService.setValue("siteInFrame", $rootScope.siteInIFrame);
    }

    // check if site is open in iPad | iPhone | ipod
    $rootScope.siteIniPad =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if ($rootScope.siteIniPad) {
      $timeout(function () {
        $(".iframe-body").css("max-width", $rootScope.screenWidth + "px");
      });
    }
    // for localhost...
    // $rootScope.siteInIFrame=true;
    // $rootScope.isSubDomain = true;
    // setTimeout(function () {
    //   $rootScope.isSubDomain = true;
    // },500)
    // for localhost
    // $rootScope.isAgent = true;

    var hostName = $location.host();
    if (!CURRENT_HOST.includes(hostName)) {
      var username = hostName.split(".")[0];
      if (username === "agents") {
        $rootScope.isAgent = true;
        rootScopeService.setValue("isAgent", $rootScope.isAgent);
        $rootScope.manualLogout();
        // $state.go('new-route-agent');
      } else {
        var isNotExternalDomain = false;
        CURRENT_HOST.forEach(function (host) {
          isNotExternalDomain = hostName.indexOf(host) > -1;
        });
        var requestObject = {};
        if (isNotExternalDomain) {
          requestObject = {
            username: username,
          };
        } else {
          requestObject = {
            username: username,
            sub_domain_url: $location.protocol() + "://" + hostName,
          };
        }
        userSocialProfileService.getUserSocialProfile(requestObject, "").then(
          function (response) {
            if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
              var userProfile = response.data.data;
              if (userProfile.role_id == 3) {
                $rootScope.isSubDomain = true;
                $rootScope.siteInIFrame = true;
                rootScopeService.setValue(
                  "siteInFrame",
                  $rootScope.siteInIFrame
                );
                $rootScope.manualLogout();
              } else if (userProfile.role_id == 5) {
                // ----todo-------
                $rootScope.isAgent = true;
                rootScopeService.setValue("isAgent", $rootScope.isAgent);
                $rootScope.isSubDomain = false;
                $rootScope.siteInIFrame = false;
                rootScopeService.setValue(
                  "siteInFrame",
                  $rootScope.siteInIFrame
                );
                $rootScope.manualLogout();
                if ($rootScope.isUserLoggedIn()) {
                  $scope.hostName = $location.host();
                  if (
                    $scope.hostName != "localhost" &&
                    username != userProfile.username
                  ) {
                    var protocol = window.location.href.split("://")[0];
                    $scope.hostName = window.location.host.substr(
                      window.location.host.indexOf(".")
                    );
                    window.location.href =
                      protocol +
                      "://" +
                      userProfile.username +
                      $scope.hostName +
                      "/agent-calendar";
                  } else {
                    if (
                      $rootScope.isAgent &&
                      !allowedAgentURLs.includes($rootScope.currentState.name)
                    ) {
                      $state.go("agent-calendar");
                    }
                  }
                } else {
                  $state.go("signin");
                }
              }
              if ($rootScope.currentState.name == "adventure-detail") {
                $rootScope.$emit("sub-domain-check");
              }
            } else if (
              response.data.code === CONST_HELPER.API_RESPONSE.UNPROCESSABLE
            ) {
              // $state.go('home');
              // if (checkActivities()) {
              //   $rootScope.activityFilterPage = true;
              //   $rootScope.showFooter = true;
              // } else {
              //   $state.go('link-expire');
              // }
            } else if (
              response.data.code === CONST_HELPER.API_RESPONSE.BAD_REQUEST
            ) {
              $state.go("home");
            }
          },
          function () {}
        );
      }
    } else if (hostName.startsWith("localhost")) {
      // in white label || subdomain || agent, do not load the heli intercom...
      if (!$rootScope.siteInIFrame && $scope.user.role_id != 3) {
        var hostUrl = REQUEST_URL.replace("/api/v1/", "");
        var heliIntercomUrl =
          hostUrl + "/api/webroot/white-label/heli-intercom.js";

        // detect prerender headless browser, not to load intercom chat lib...
        if (!/Prerender/.test(window.navigator.userAgent)) {
          $scope.loadScript(heliIntercomUrl, function () {});
          // intercom chat configurations...
          window.intercomSettings = {
            app_id: "v4lm2xej",
          };
        }
      }
    } else {
      if (!$rootScope.siteInIFrame && $scope.user.role_id != 3) {
        var hostUrl = REQUEST_URL.replace("/api/v1/", "");
        var heliIntercomUrl =
          hostUrl + "/api/webroot/white-label/heli-intercom.js";

        // detect prerender headless browser, not to load intercom chat lib...
        if (!/Prerender/.test(window.navigator.userAgent)) {
          $scope.loadScript(heliIntercomUrl, function () {});
          // intercom chat configurations...
          window.intercomSettings = {
            app_id: "v4lm2xej",
          };
        }
      }
      $rootScope.isAgent = false; // set isAgent false on operator heli dev
      userSocialProfileService.setAgent($rootScope.isAgent);
    }

    // load facebook event.js , twitter widget and google map api after page load...
    // var facebookEventjsUrl = "";
    var twitterWidgetUrl = CONST_HELPER.twitterWidgetUrl;
    // var googleMapApiUrl = CONST_HELPER.googleMapApiUrl;
    window.addEventListener("load", function () {
      $scope.loadScript(twitterWidgetUrl, function () {});
      // $scope.loadScript(googleMapApiUrl, function () {});
    });
    // format acvtivity name as required...
    $scope.formatActivityName = function (acvtivityName) {
      if (acvtivityName === "Ski & Ride") {
        return "ski&ride";
      }
      return acvtivityName.toLowerCase();
    };

    $scope.showLoader = function () {
      // show the loader
      if ($scope.loader) return;
      $scope.loader = true;
    };

    $rootScope.showLoaderRootScope = function () {
      // show the loader
      if ($scope.loader) return;
      $scope.loader = true;
    };

    $rootScope.hideLoaderRootScope = function () {
      // hide the loader
      if (!$scope.loader) return;
      $scope.loader = false;
    };

    $scope.hideLoader = function () {
      // hide the loader
      if (!$scope.loader) return;
      $scope.loader = false;
      if (!$scope.$$phase) {
        $scope.$apply();
      }
    };

    $scope.showPopup = function (modalId) {
      // show the popup
      $(modalId).modal("show");
    };

    $scope.hidePopup = function (modalId) {
      // hide the hide
      $(modalId).modal("hide");
      // document.getElementsByTagName('body')[0].classList.remove('modal-open');
      document
        .getElementsByTagName("body")[0]
        .className.replace(" modal-open ", " ");
      $("body").css("padding-right", "0px");
      if (angular.element(document.querySelector(".modal-backdrop")).length) {
        var el = document.getElementsByClassName("modal-backdrop")[0];
        // document.getElementsByClassName("modal-backdrop")[0].remove();
        el.parentNode.removeChild(el);
      }
    };

    $scope.userNameValidation = function (username) {
      // user name validaitons
      var regex = /^[a-z0-9_.-]*$/;
      return regex.test(username);
    };

    $scope.playVideo = function () {
      $scope.playHomeVideo = true;
      $scope.showLoader();
      $scope.hideLoader();
    };

    // var body = angular.element(document.getElementsByTagName('body'))

    $scope.fixFocus = function () {
      // var mainDiv=angular.element(document.getElementsByTagName('body'))
      // body[0].className=body[0].className + " fix-scroll";

      var ele = angular.element(
        document.getElementsByClassName("select2-input")
      );
      $timeout(function () {
        ele[0].focus();
      }, 100);
    };
    $scope.background = parallaxHelper.createAnimator(-0.3, 200, -200);
    $scope.slickConfig = {
      enabled: true,
      autoplay: true,
      draggable: false,
      autoplaySpeed: 2000,
      slidesToShow: 8,
      slidesToScroll: 1,
    };

    $scope.slickHeliPartnerConfig = {
      enabled: true,
      autoplay: false,
      draggable: false,
      slidesToShow: 4,
      slidesToScroll: 1,
    };

    $scope.adventureBreakpoints = [
      {
        breakpoint: "767",
        settings: {
          slidesToShow: 1,
          centerPadding: "50px",
          slidesToScroll: 1,
          centerMode: true,
          autoplay: true,
        },
      },
      {
        breakpoint: "1025",
        settings: {
          centerPadding: "150px",
          slidesToScroll: 1,
        },
      },
    ];

    $scope.partnerBreakpoints = [
      {
        breakpoint: 569,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ];
    $scope.feedBreakpoints = [
      {
        breakpoint: 679,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 2000,
        },
      },
    ];
    $scope.breakpoints = [
      {
        breakpoint: 1601,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ];

    $scope.speed = window.mobileAndTabletcheck() ? 21 : 5.1;
    var previousScroll = 0;
    var currentScroll = 0;
    window.addEventListener(
      "scroll",
      function () {
        if (
          $rootScope.userRole === "operator" ||
          $rootScope.userRole === "agent" ||
          $rootScope.screenWidth < CONST_HELPER.SCREEN.MOBILE_WIDTH
        ) {
          return;
        }

        currentScroll = $(window).scrollTop();
        if (
          previousScroll < currentScroll &&
          currentScroll > CONST_HELPER.SCREEN.SCROLL_HEIGHT
        ) {
          $(".header-main-nav").addClass("height0");
        } else {
          $(".header-main-nav").removeClass("height0");
        }
        var scrollTop =
          window.pageYOffset !== undefined
            ? window.pageYOffset
            : (
                document.documentElement ||
                document.body.parentNode ||
                document.body
              ).scrollTop;
        var parallaxNode = $("[ng-parallax]");

        if (parallaxNode && parallaxNode.length > 0) {
          $timeout(function () {
            var top = parallaxNode[0].offsetTop;
            if (scrollTop > top) {
              $scope.speed = window.mobileAndTabletcheck() ? 21 : 5.1;
            } else {
              $scope.speed = 0;
            }
          });
        }
        previousScroll = $(window).scrollTop();
      },
      {
        passive: true,
      }
    );

    $rootScope.$on(
      "event:social-sign-in-success",
      function (event, userDetails) {
        // event after user logs in from social media
        var data = {
          social_type: userDetails.provider.toLowerCase(),
          social_user_id: userDetails.uid,
          name: userDetails.name,
        }; // collect the user info to send the request
        $scope.showLoader();
        userRegisterService.isAlreadyUser(data).then(
          function (response) {
            if (response.data) {
              if (response.data.code === "202" && !$rootScope.siteInIFrame) {
                $scope.hideLoader();
              }
              if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
                // user already exists log in directly
                $scope.setUserDetails(response.data.data, "second");

                if (
                  $rootScope.previousState.name === "mergeAccountSuccess" ||
                  $rootScope.previousState.name === "forgotPassword" ||
                  $rootScope.previousState.name === "forgot-success"
                ) {
                  $state.go("home");
                } else {
                  $window.history.back();
                }
                $scope.hideLoader();
              } else if (response.data.code === "202") {
                // new registeration
                if ($rootScope.siteInIFrame) {
                  var email = {
                    email: userDetails.email,
                  }; // email address
                  userAdventureDetailService.checkUserEmail(email).then(
                    function (res) {
                      // post to the server the user email
                      if (res.data) {
                        $scope.hideLoader();
                        if (res.data.code === CONST_HELPER.API_RESPONSE.OK) {
                          // if email does not exist.. treat them as guest user...
                          $scope.setUserDetails(res.data.data, "second"); // set user details in the rootScope;
                          // $scope.user = res.data.data;//set user data into local scope;=
                          // $scope.showPopup("#guestConfirmation")
                          $rootScope.$broadcast(
                            "wl-social-signin",
                            res.data.data
                          ); // Removing guest confirmation call
                        } else if (
                          res.data.code ===
                          CONST_HELPER.API_RESPONSE.UNPROCESSABLE
                        ) {
                          $scope.showPopup("#socialAccountMergeAlert");
                        } else if (
                          res.data.code ===
                            CONST_HELPER.API_RESPONSE.BAD_REQUEST &&
                          res.data.message &&
                          res.data.message.email
                        ) {
                          $scope.showPopup("#socialAccountEmailAlert");
                        }
                      }
                    },
                    function () {
                      $scope.showPopup("#serverErrModal");
                    }
                  );
                } else if ($rootScope.currentState.name !== "signup") {
                  $rootScope.$broadcast("social-log-in", userDetails, response);
                } else {
                  $rootScope.$broadcast(
                    "social-sign-in",
                    userDetails,
                    response
                  );
                }
              } else if (
                response.data.code === CONST_HELPER.API_RESPONSE.UNPROCESSABLE
              ) {
                // user account is deactivated...
                $scope.showPopup("#userDeactivated");
                $scope.hideLoader();
              }
            }
          },
          function () {
            $scope.showPopup("#serverErrModal");
          }
        );
      }
    );

    $rootScope.menu = {};
    $rootScope.menu.openOverlay = 0;
    $rootScope.menu.openNav = function () {
      document.getElementById("mySidenav").style.opacity = "1";
      document.getElementById("mySidenav").style.visibility = "visible";
      document.getElementById("mySidenav").style.zIndex = "100000";
      document.getElementById("mySidenav").style.transition = "opacity .6s";
      document.querySelector("body").style.position = "fixed";
      $rootScope.menu.openOverlay = 1;
    };

    $rootScope.menu.closeNav = function () {
      document.getElementById("mySidenav").style.opacity = "0";
      document.getElementById("mySidenav").style.visibility = "hidden";
      document.getElementById("mySidenav").style.zIndex = "1";
      document.getElementById("mySidenav").style.transition =
        "opacity .5s,visibility .2s .5s";

      document.querySelector("body").style.position = "inherit";
      $rootScope.menu.openOverlay = 0;
    };

    $scope.checkUserRoles = function () {
      // check for user roles for redirection to dashboards
      var user = $cookies.get("user");
      if (user) {
        user = JSON.parse(user);
        if (user.role_id == "3") {
          // if user is an operator
          if (!$rootScope.isAgent) {
            if (user.is_profile_completed) {
              $state.go("operator-dashboard");
              return false;
            } else {
              $state.go("operator-get-started");
              return false;
            }
          } else {
            $scope.logout();
          }
        } else if (user.role_id == "5") {
          if (!$rootScope.isAgent) {
            $scope.logout();
          } else {
            if (
              $rootScope.isAgent &&
              !allowedAgentURLs.includes($rootScope.currentState.name)
            ) {
              $state.go("agent-calendar");
            }
          }
        }
      }
    };

    $scope.changePassword = function (form) {
      $scope.oldPasswordErr = form.oldpass.$pristine
        ? true
        : form.oldpass.$viewValue
        ? form.oldpass.$viewValue.trim() === ""
        : true;
      $scope.changePasswordErr = form.changePassword.$pristine
        ? true
        : form.changePassword.$viewValue
        ? form.changePassword.$viewValue.trim() === ""
        : true;

      if (
        $scope.changePasswordModel.changePassword &&
        $scope.changePasswordModel.changePassword.trim() === ""
      ) {
        $scope.changePasswordErr = true; // password is blank
      }

      if (!$scope.changePasswordErr) {
        $scope.changeConfirmPassErr = form.changeConfirmPassword.$pristine
          ? true
          : form.changeConfirmPassword.$viewValue
          ? !!(
              form.changeConfirmPassword.$viewValue.trim() === "" ||
              form.changePassword.$viewValue.trim() !==
                form.changeConfirmPassword.$viewValue.trim()
            )
          : true;
      }
      if (
        $scope.changePasswordModel.oldpass ==
        $scope.changePasswordModel.changePassword
      ) {
        $scope.showErrorSamePassword = true;
      }

      var data = {
        token: $rootScope.user.token,
        old_password: $scope.changePasswordModel.oldpass,
        new_password: $scope.changePasswordModel.changeConfirmPassword,
      };
      if (
        !$scope.changePasswordErr &&
        !$scope.oldPasswordErr &&
        !$scope.changeConfirmPassErr &&
        !$scope.showErrorSamePassword
      ) {
        $scope.showLoader();
        operatorService.changeOperatorPass(data).then(
          function (response) {
            // post to the server the new  password
            if (response.data) {
              if (response.data.code !== CONST_HELPER.API_RESPONSE.OK) {
                // some error exits
                switch (response.data.code) {
                  case CONST_HELPER.API_RESPONSE.UNPROCESSABLE:
                    $scope.oldPassNotMatch = true;
                    $scope.oldPassNotMatchErr = response.data.message;
                    break;
                  case CONST_HELPER.API_RESPONSE.BAD_REQUEST:
                    $scope.changePasswordServerErr = true; // set error status
                    $scope.changePwdServerErrMessage = response.data.message;
                    break;
                  default:
                    $scope.changePasswordServerErr = true; // set error status
                    $scope.changePwdServerErrMessage = response.data.message;
                    // for (var key in response.data.message[Object.keys(response.data.message)]) {
                    //     $scope.changePwdServerErrMessage = response.data.message[Object.keys(response.data.message)][key];
                    // }
                    break;
                }
                $scope.hideLoader();
              } else {
                $scope.initializeValue("close"); // reset the change password modal
                $scope.isSuccessfull = true;
                $scope.hideLoader();
              }
            }
          },
          function () {
            $scope.hideLoader();
            $scope.showPopup("#serverErrModal");
          }
        );
      }
    };
    // initialize the change password modal on different scenario
    $scope.initializeValue = function (param) {
      switch (param) {
        case "old": // ng-keyup event on old password text
          $scope.oldPassNotMatch = false;
          break;
        case "confirm": // ng-keyup event on confirm password text
          $scope.changeConfirmPassErr = false;
          $scope.changePasswordServerErr = false;
          break;
        case "close": // its when success message is shown
          $scope.changePasswordModel = {};
          $scope.oldPasswordErr = false;
          $scope.oldPassNotMatch = false;
          $scope.changePasswordErr = false;
          $scope.changePasswordServerErr = false;
          $scope.changeConfirmPassErr = false;
          break;
        case "new":
          $scope.changePasswordErr = false;
          $scope.form.userChangePassword.changePassword.$dirty = true;
          if (
            $scope.changePasswordModel.oldpass ==
            $scope.changePasswordModel.changePassword
          ) {
            $scope.showErrorSamePassword = true;
          } else {
            $scope.showErrorSamePassword = false;
          }
          break;
        default: // when change password modal is closed or opened
          $scope.isSuccessfull = false;
          $scope.changePasswordModel = {};
          var form = $scope.form.userChangePassword;
          // dirty check the user has interacted with the form.closing the modal when there were errors on the form were not
          // seting the dirty property to false
          form.changePassword.$dirty = false;
          form.changeConfirmPassword.$pristine = true;
          // value of new password text field were not getting cleared, when modal is closed with red errors on it
          $("#changePassword").val(null);
          $scope.oldPasswordErr = false;
          $scope.oldPassNotMatch = false;
          $scope.changePasswordErr = false;
          $scope.changePasswordServerErr = false;
          $scope.changeConfirmPassErr = false;
          break;
      }
    };

    $scope.homeRedirection = function () {
      // redirection on home logo click
      if ($scope.user && $scope.user.role_id) {
        if ($scope.user.role_id === 3) {
          // if user is a operator
          if ($scope.user.is_profile_completed) {
            // and his profile is complete
            if ($scope.currentState.name === "operatorCreateAdventure") {
              // for operator Create Adventure redirection
              return false;
            } else if ($scope.currentState.name === "operator-edit-profile") {
              // for operator Create Adventure redirection
              return false;
            } else if ($scope.currentState.name === "operator-get-started") {
              // for operator Create Adventure redirection
              return false;
            } else if ($scope.currentState.name === "operator-basic-profile") {
              // for operator Create Adventure redirection
              $state.go("operator-edit-profile");
              return false;
            } else {
              if ($scope.currentState.name === "signin") {
                // for operator Create Adventure redirection
                $state.go("operator-calendar");
                return false;
              } else {
                $state.go($scope.currentState.name);
                return false;
              }
            }
          } else {
            // incomplete profile redirect to profile completion screen
            if (
              $scope.currentState.name === "operator-basic-profile" ||
              $scope.currentState.name === "operator-dashboard" ||
              $scope.currentState.name === "operator-add-location" ||
              $scope.currentState.name === "operatorCaseSelection" ||
              $scope.currentState.name === "operatorCreateAdventure"
            ) {
              // if on profile filling page stay there
              return false;
            } else {
              // else start filling profile
              $state.go("operator-get-started");
              return false;
            }
          }
        } else if (
          $scope.user.role_id === 5 &&
          $scope.currentState.name === "signin"
        ) {
          $scope.hostName = $location.host();
          if ($scope.hostName != "localhost") {
            var protocol = window.location.href.split("://")[0];
            $scope.hostName = window.location.host.substr(
              window.location.host.indexOf(".")
            );
            window.location.href =
              protocol +
              "://" +
              $scope.user.username +
              $scope.hostName +
              "/agent-calendar";
          } else {
            if (
              $rootScope.isAgent &&
              !allowedAgentURLs.includes($rootScope.currentState.name)
            ) {
              $state.go("agent-calendar");
            }
          }
        } else if ($scope.user.role_id !== 5) {
          // else go to home page
          $state.go("home");
          return false;
        }
      } else {
        if (!$scope.user || ($scope.user && !$scope.user.token)) {
          // check for user logged in status
          $state.go("signin");
          // return false;
        } else {
          $state.go("home");
          // return false;
        }
      }
    };

    $rootScope.isConnect = false;
    $scope.authenticate = function (provider, isConnect) {
      $rootScope.isConnect = isConnect || false; // twitter authentication
      switch (provider) {
        case "twitter":
          window.open(
            REQUEST_URL + "twitters/connect?connect=twitter",
            "targetWindow",
            "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500, height=500"
          );
          break;
        case "linkedIn": // for social connect account
          window.open(
            REQUEST_URL + "linkedin/connect?connect=linkedin",
            "targetWindow",
            "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500, height=500"
          );
          break;
      }
    };

    $scope.getCountryName = function () {
      /* create funtion for get country name */
      operatorService.getCountry().then(function (data) {
        /* Call api for operator signup */
        if (data.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.countryName = data.data.data;
        }
      });
    };
    $scope.getAllCountryName = function (isCache) {
      isCache = isCache === undefined ? true : !!isCache;

      var self = this;
      var deferred = $q.defer();

      if (isCache && self.countriesApiResponse) {
        deferred.resolve(self.countriesApiResponse);
      } else {
        userAdventureDetailService.getAllCountry().then(
          function (data) {
            if (isCache) {
              self.countriesApiResponse = data;
            }

            if (data.data.code === CONST_HELPER.API_RESPONSE.OK) {
              $scope.allcountryName = data.data.data;
            }

            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      }

      return deferred.promise;
    };

    $scope.months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    $scope.logoRedirection = function () {
      // logo redirection
      // $window.scrollTo(0,0); // redirect on top when location change
      $rootScope.homeOffset = 0;
      if ($scope.user && $scope.user.role_id) {
        if ($rootScope.isAgent) {
          $state.go("agent-calendar");
        } else if ($scope.user.role_id === 3) {
          // if user is a operator
          if ($scope.user.is_basic_profile_completed) {
            // and his profile is complete
            $state.go("operator-dashboard");
            return false;
          } else {
            $state.go("operator-get-started");
            return false;
          }
        } else {
          // else go to home page
          $state.go("home");
        }
      } else {
        // else go to home page
        $state.go("home");
      }
    };

    $scope.gotoRadar = function (isUserLoggedIn) {
      // radar redirection
      if (isUserLoggedIn) {
        $state.go("consumer-radar");
      } else {
        $state.go("signin");
        $cookies.put("goToRadar", true);
        // $window.localStorage.goToRadar=true;
      }
    };
    // fucntion to redirect the adventure detail page...
    $scope.goToAdventureDetail = function (item) {
      item.package = item.package || item;
      item.operator_profile = item.operator_profile || item.operator_details;
      item.package_id = item.package_id || item.id;
      if (item.package.package_type == CONST_HELPER.PACKAGE_TYPE.PRIVATE) {
        $state.go("adventure-detail-private", {
          operatorname: $scope.getSeoFriendlyURL(
            item.operator_profile.business_name
          ),
          packagename: $scope.getSeoFriendlyURL(item.package.name),
          package_id: item.package_id,
          package_status: item.package.status == 1 ? "active" : "expired",
        });
      } else {
        $state.go("adventure-detail", {
          operatorname: $scope.getSeoFriendlyURL(
            item.operator_profile.business_name
          ),
          packagename: $scope.getSeoFriendlyURL(item.package.name),
          package_id: item.package_id,
          package_status: item.package.status == 1 ? "active" : "expired",
        });
      }
    };
    // order slots by date ascending order...
    $rootScope.sortSlots = function (slot) {
      var date = new Date(slot.from_date);
      return date;
    };
    // set package case and package type in cookies
    $scope.setPackageCaseAndType = function (packg) {
      var packageCase = packg.package_case ? packg.package_case : "";
      var packageType = packg.package_type ? packg.package_type : "1";

      $rootScope.packageType = packageType;
      $rootScope.packageCase = packageCase;
      $cookies.put("package_type", packageType);
      $cookies.put("package_case", packageCase);
    };

    // validate the email everytime it get inserted
    $scope.emailAdded = function (email) {
      $scope.noSendEmail = false;
      if (validateEmail(email.text)) {
        $scope.errEmailFormat = false;
        $scope.recipients.push(email.text);
      } else {
        $scope.errEmailFormat = true;
      }
    };

    function validateEmail(email) {
      var re =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      return re.test(email);
    }

    // called from userRadar.js , userAdventureDetail.js
    $scope.initializeMail = function (packageData) {
      // initialize the mail modal, whenever it opens
      $scope.hidePopup("#share");
      $scope.hidePopup("#operator-adventure-share"); // share modal in oeprator package listing
      $scope.sendToEmail = [];
      $scope.recipients = [];
      $scope.noShortUrl = false;
      $scope.noSendEmail = false;
      $scope.mail = {
        description: $scope.removeHtmlTags(packageData.description),
        short_url: Array.isArray(packageData.short_url)
          ? packageData.short_url[0]
          : packageData.short_url,
        from_page: packageData.from_page ? packageData.from_page : "",
        package_id: packageData.id,
      };
    };

    // to initialize the share adventure modal for share on heli feed
    $scope.initializeHeliShareModal = function (
      packageData,
      media,
      packageSlotsOtherData,
      postType,
      itemSlot
    ) {
      $scope.hidePopup("#share");
      $scope.hidePopup("#operator-adventure-share"); // share modal in oeprator package listing
      $rootScope.isUserLoggedIn();
      $scope.heliShare = {
        profile_pic: $scope.user.profile_pic ? $scope.user.profile_pic : "",
        user_name: $scope.user.name,
        description: $scope.removeHtmlTags(packageData.description),
        short_url: packageData.short_url,
        package_media: media || "",
        package_name: packageData.name ? packageData.name : "",
        comment: "",
        package_id: packageData.package_id || packageData.id,
        location: packageData.location_name,
        duration: packageData.duration,
        min_price: getMinimumPrice(
          packageData,
          packageSlotsOtherData,
          itemSlot
        ),
        post_type: postType,
        currency_code: itemSlot
          ? itemSlot.currency.code
          : $scope.shareAdventureData
          ? $scope.shareAdventureData.currency.code
          : packageData.currency_code,
        slot: packageData.slot
          ? packageData.slot.from_date
            ? packageData.slot
            : ""
          : "",
      };
    };
    // initialize share on heli from feeds
    $scope.initializeHeliFeedShare = function (feed) {
      $scope.feedShare = feed;
      $scope.feedShare.profile_pic = $scope.user.profile_pic
        ? $scope.user.profile_pic
        : "";
      $scope.feedShare.user_name = $scope.user.name;
      $scope.feedShare.comment = "";
    };

    // function to get the minimum price of package
    function getMinimumPrice(packageData, packageSlotsOtherData, itemSlot) {
      if (parseInt(packageSlotsOtherData.min_price) > CONST_HELPER.ZERO) {
        return packageSlotsOtherData.min_price;
      } else if (packageData.slot && packageData.slot.slot_lodgings) {
        return (
          packageData.slot.min_price || packageData.slot.slot_lodgings[0].price
        );
      } else if (
        itemSlot &&
        (itemSlot.package_slots || itemSlot.cart_slot_lodging_details)
      ) {
        return (
          itemSlot.package_slots.min_price ||
          (itemSlot.package_slots.slot_lodgings &&
            itemSlot.package_slots.slot_lodgings[0].price) ||
          (itemSlot.cart_slot_lodging_details.length &&
            itemSlot.cart_slot_lodging_details[0].slot_lodging.price)
        );
      } else if (
        $scope.shareAdventureData.package_slots &&
        $scope.shareAdventureData.package_slots[0].slot_lodgings.length
      ) {
        return (
          $scope.shareAdventureData.minAdultPrice ||
          $scope.shareAdventureData.package_slots[0].slot_lodgings[0].price
        );
      }
      // packageSlotsOtherData.min_price || (packageData.slot && packageData.slot.slot_lodgings && packageData.slot.slot_lodgings.length && packageData.slot.slot_lodgings[0].price) || (itemSlot && ((itemSlot.package_slots && itemSlot.package_slots.min_price))) || ($scope.shareAdventureData.package_slots[0].slot_lodgings.length && $scope.shareAdventureData.package_slots[0].slot_lodgings[0].price)
    }

    $scope.shareOnHeli = function () {
      // share content from complete heli application except the feeds
      $scope.showLoader();
      var data = {
        say_something: $scope.heliShare.comment,
        package_id: $scope.heliShare.package_id,
        token: $scope.user.token,
        post_type: $scope.heliShare.post_type,
        slots_id: $scope.heliShare.slot ? $scope.heliShare.slot.id : "",
      };
      sharedService.shareOnHeli(data).then(
        function (res) {
          if (res.data.code === CONST_HELPER.API_RESPONSE.OK) {
            $scope.hideLoader();
            $scope.successMsg = MESSAGE_HELPER.sharing.EMAIL_SEND_SUCCESSFULLY;
            rootScopeService.setValue("successMsg", $scope.successMsg);
            $scope.hidePopup("#heli-share");
            $scope.showPopup("#success");
          } else {
            $scope.hideLoader();
            $scope.hidePopup("#heli-share");
            $scope.showPopup("#serverErrModal");
          }
        },
        function () {
          $scope.hideLoader();
          $scope.showPopup("#serverErrModal");
        }
      );
    };

    $scope.shareOnHeliFeed = function () {
      // share feed post on heli feed
      $scope.showLoader();
      var data = {
        say_something: $scope.feedShare.comment,
        token: $scope.user.token,
        feed_id: $scope.feedShare.main_post
          ? $scope.feedShare.main_post.id
          : $scope.feedShare.id, // share the main post if its their
      };
      sharedService.shareOnHeliFeed(data).then(
        function (res) {
          // share feed post on heli feed
          if (res.data.code === CONST_HELPER.API_RESPONSE.OK) {
            $scope.hideLoader();
            $scope.successMsg =
              MESSAGE_HELPER.sharing.FEED_POST_SHARE_SUCCESSFULLY;
            rootScopeService.setValue("successMsg", $scope.successMsg);
            $scope.hidePopup("#heli-share-feed");
            $scope.showPopup("#success");
          } else if (res.data.code === CONST_HELPER.API_RESPONSE.NOT_FOUND) {
            $scope.hideLoader();
            $scope.hidePopup("#heli-share-feed");
            $scope.showPopup("#feedNotExist"); // when feed no longer exists.
            $rootScope.$broadcast("removePost", $scope.feedShare);
          } else {
            $scope.hideLoader();
            $scope.hidePopup("#heli-share-feed");
            $scope.showPopup("#serverErrModal");
          }
        },
        function () {
          $scope.hideLoader();
          $scope.showPopup("#serverErrModal");
        }
      );
    };

    // share slot on adventure view detail  and operator pacakge detail page
    $scope.setShareSlotData = function (slot, isSLotSelected) {
      if (isSLotSelected && slot) {
        $scope.shareSelectedSlot = slot; // this particular slot is been selected for sharing along with adventure
      } else {
        $scope.shareSelectedSlot = {};
      }
    };

    // to initialize slot share modal
    $scope.initializeAdventureShareModal = function (
      slots,
      packageData,
      postType
    ) {
      // called when slot share modal opens
      $scope.shareSelectedSlot = {};
      $scope.shareAdventureSlots = [];
      $scope.shareAdventureSlots = slots;
      $scope.shareAdventureData = {};
      $scope.shareAdventureData = packageData;
      $scope.shareAdventureData.package_medias =
        packageData.package_medias.filter(function (pm) {
          return pm.media_type === 0 && pm.is_main === 1;
        });
      $scope.shareAdventureData.post_type = postType; // it can be share either from user adventure detail or Operator pacakge detail
      // initialize advenute radio button
      angular
        .element(document.getElementById("option-adventure"))
        .prop("checked", true);
      // $scope.showPopup("#share-adventure");
      $scope.continueSlotShare(false, $scope.shareAdventureData);
      $scope.showPopup("#share");
    };

    // to show share modal on click of continue button on slot share modal
    $scope.continueSlotShare = function (isBack, packageData) {
      // invoke when continue button is click
      if (!isBack && packageData) {
        $scope.hidePopup("#share-adventure");
        $timeout(function () {
          $scope.showPopup("#share");
        }, 400);
        $scope.shareSlotContent = {
          // initialize the content which is to be share on different sharing options
          description: $scope.removeHtmlTags(
            packageData.description +
              "\n" +
              ($scope.shareSelectedSlot.from_date
                ? moment($scope.shareSelectedSlot.from_date).format("MMM d") +
                  " - "
                : "") +
              ($scope.shareSelectedSlot.to_date
                ? moment($scope.shareSelectedSlot.to_date).format("MMM D YYYY")
                : "")
          ),
          short_url: getSlotShortUrl(packageData, $scope.shareSelectedSlot), // packageData.short_url || packageData.package.short_url ,
          slot: $scope.shareSelectedSlot,
          profile_pic: $scope.user.profile_pic ? $scope.user.profile_pic : "",
          package_name: packageData.name ? packageData.name : "",
          package_id: packageData.package_id || packageData.id,
          location_name: packageData.location_name,
          duration: packageData.duration,
          id: packageData.id,
          package_type: packageData.package_type,
        };
      } else {
        // move back to slot share option
        $scope.hidePopup("#share");
        $timeout(function () {
          $scope.showPopup("#share-adventure");
        }, 400);
        $scope.shareSlotContent = {};
      }
    };

    // set minimum zoom level
    $scope.$on("mapInitialized", function (evt, evtMap) {
      var map = evtMap;
      map.setOptions({ minZoom: 2 }); // set maxzoom and minzoom
    });

    // function to remove html tags
    $scope.removeHtmlTags = function (htmldata) {
      var div = document.createElement("div");
      div.innerHTML = htmldata;
      var text = div.textContent || div.innerText || "";
      return text;
    };

    // function to get slot short url
    function getSlotShortUrl(packageData, slot) {
      var shortUrl = "";
      if (slot.hasOwnProperty("id")) {
        // if slot sharing
        shortUrl = packageData.short_url || packageData.package.short_url;
        shortUrl = shortUrl + "/" + slot.id;
      } else {
        // package sharing
        shortUrl = packageData.short_url || packageData.package.short_url;
      }

      return shortUrl;
    }

    // called from userRadar.js , userAdventureDetail.js
    $scope.sendEmail = function (params) {
      $rootScope.isUserLoggedIn(); // check id user is logged in
      if (!$scope.recipients.length || !$scope.sendToEmail.length) {
        $scope.noSendEmail = true;
      }
      if (
        !$scope.noSendEmail &&
        !$scope.errEmailFormat &&
        !params.comment.$error.maxlength
      ) {
        $scope.showLoader();
        var data = {
          email: {
            recipients: $scope.recipients,
          },
          description: $scope.mail.description,
          short_url: $scope.mail.short_url,
          token: $scope.user.token,
          package_id: $scope.mail.package_id,
        };
        sharedService.sendEmail(data).then(
          function (res) {
            if (res.data.code === CONST_HELPER.API_RESPONSE.OK) {
              $scope.hideLoader();
              // feed can be shared from email from feeds and adventure pages
              switch ($scope.mail.from_page) {
                case CONST_HELPER.page_name.FEED:
                  $scope.successMsg =
                    MESSAGE_HELPER.sharing.FEED_POST_SHARE_SUCCESSFULLY;
                  rootScopeService.setValue("successMsg", $scope.successMsg);
                  break;
                case CONST_HELPER.page_name.REFERRAL:
                  $scope.successMsg =
                    MESSAGE_HELPER.sharing.INVITE_SHARE_SUCCESSFULLY;
                  rootScopeService.setValue("successMsg", $scope.successMsg);
                  break;
                default:
                  $scope.successMsg =
                    MESSAGE_HELPER.sharing.EMAIL_SEND_SUCCESSFULLY;
                  rootScopeService.setValue("successMsg", $scope.successMsg);
                  break;
              }
              $scope.hidePopup("#email");
              $scope.showPopup("#success");
            } else {
              switch (Object.keys(res.data.message)[0]) {
                case "short_url":
                  $scope.noShortUrl = true;
                  break;
                case "description":
                  $scope.noDescription = true;
                  break;
                case "email":
                  $scope.noSendEmail = true;
                  break;
              }
              $scope.hideLoader();
            }
          },
          function () {
            $scope.hideLoader();
            $scope.showPopup("#serverErrModal");
          }
        );
      }
    };

    $rootScope.$on("event:social-fb-account-connect", function (event, data) {
      // event after user logs in from social media
      data.token = $rootScope.user.token;
      sharedService.connectFBAccountReq(data).then(function (res) {
        switch (res.data.code) {
          case CONST_HELPER.API_RESPONSE.UNAUTHERIZED:
            $scope.hideLoader();
            $scope.logout(); // unauthorised user
            $state.go("signin");
            break;
          case CONST_HELPER.API_RESPONSE.OK:
            $scope.setSocialAccountsRootscope(
              res.data.data.role_id,
              res.data.data.social_accounts
            );
            $scope.hideLoader();
            break;
          default:
            $scope.hideLoader();
        }
      });
    });

    $rootScope.$on(
      "event:social-linkedin-connect-success",
      function (event, data) {
        sharedService.connectLinkedInAccount(data).then(
          function (res) {
            switch (res.data.code) {
              case CONST_HELPER.API_RESPONSE.UNAUTHERIZED:
                $scope.hideLoader();
                $scope.logout(); // unauthorised user
                $state.go("signin");
                break;
              case CONST_HELPER.API_RESPONSE.OK:
                $scope.setSocialAccountsRootscope(
                  res.data.data.role_id,
                  res.data.data.social_accounts
                );
                $scope.hideLoader();
                break;
              default:
                $scope.hideLoader();
                break;
            }
          },
          function () {
            $scope.hideLoader();
            $scope.showPopup("#serverErrModal");
          }
        );
      }
    );

    $rootScope.$on(
      "event:social-twitter-connect-success",
      function (event, userDetails) {
        var data = {
          twitter_user_id: userDetails.uid,
          twitter_username: userDetails.username ? userDetails.username : "",
          twitter_oauth_token: userDetails.twitter_oauth_token,
          twitter_oauth_token_secret: userDetails.twitter_oauth_token_secret,
          token: $rootScope.user.token,
        };
        sharedService.connectTwitterAccount(data).then(
          function (res) {
            switch (res.data.code) {
              case CONST_HELPER.API_RESPONSE.UNAUTHERIZED:
                $scope.hideLoader();
                $scope.logout(); // unauthorised user
                $state.go("signin");
                break;
              case CONST_HELPER.API_RESPONSE.OK:
                $scope.setSocialAccountsRootscope(
                  res.data.data.role_id,
                  res.data.data.social_accounts
                );
                $scope.hideLoader();
                break;
            }
          },
          function () {
            $scope.hideLoader();
            $scope.showPopup("#serverErrModal");
          }
        );
      }
    );

    $scope.socialDisConnect = function (social) {
      var data = {
        token: $rootScope.user.token,
        social_type: social,
      };
      $scope.showLoader();
      sharedService.disconnectSocialAccount(data).then(
        function (res) {
          switch (res.data.code) {
            case CONST_HELPER.API_RESPONSE.UNAUTHERIZED:
              $scope.hideLoader();
              $scope.logout(); // unauthorised user
              $state.go("signin");
              break;
            case CONST_HELPER.API_RESPONSE.OK:
              $scope.setSocialAccountsRootscope(
                res.data.data.role_id,
                res.data.data.social_accounts
              );
              $scope.hideLoader();
              break;
          }
        },
        function () {
          $scope.hideLoader();
          $scope.showPopup("#serverErrModal");
        }
      );
    };

    // update the rootscope
    $scope.setSocialAccountsRootscope = function (userType, socialAccounts) {
      switch (userType) {
        case 2: // user is consumer
        case 4: // user is influencer
          $rootScope.consumerSocialAccounts = socialAccounts;
          $rootScope.consumerSocialAccounts.facebook_auto_sharing =
            socialAccounts.facebook_auto_sharing === 1;
          $rootScope.consumerSocialAccounts.twitter_auto_sharing =
            socialAccounts.twitter_auto_sharing === 1;
          $rootScope.consumerSocialAccounts.linkedin_auto_sharing =
            socialAccounts.linkedin_auto_sharing === 1;
          break;
        case 3:
          $rootScope.operatorSocialAccounts = socialAccounts; // user is operator
          $rootScope.operatorSocialAccounts.facebook_auto_sharing =
            socialAccounts.facebook_auto_sharing === 1;
          $rootScope.operatorSocialAccounts.twitter_auto_sharing =
            socialAccounts.twitter_auto_sharing === 1;
          $rootScope.operatorSocialAccounts.linkedin_auto_sharing =
            socialAccounts.linkedin_auto_sharing === 1;
          break;
      }
    };

    $rootScope.factoryShowLoader = function () {
      // show the loader
      $scope.loader = true;
    };
    $rootScope.factoryHideLoader = function () {
      // hide the loader
      $scope.loader = false;
    };

    $scope.checkSavedCard = function () {
      // check if card is being saved
      if ($scope.currentState.name === "consumer-profile") {
        $rootScope.$broadcast("card-saved"); // broadcast for card saving
      }
    };
    var cartId;
    // #function to get the cart list...
    function getUserCartListCount(token) {
      cartId = $cookies.get("cart_id") ? $cookies.get("cart_id") : "";
      if (token || cartId) {
        userAdventureDetailService.getCartlistCount(cartId, token).then(
          function (res) {
            if (res && res.data && res.data.message === "Success") {
              $rootScope.myCartLength = res.data.numFound;
              rootScopeService.setValue(
                "myCartLength",
                $rootScope.myCartLength
              );
              $cookies.put("cart_id", res.data.cartId);
            } else if (res && res.data && res.data.code === 401) {
              // invalid token or Guest user
              $scope.logout();
            }
          },
          function () {
            $scope.showPopup("#serverErrModal");
          }
        );
      }
    }

    $scope.autoSharingStatus = function (social, event) {
      var data = {
        is_auto_sharing: event ? 1 : 0,
        social_type: social,
        token: $scope.user.token,
      };
      $scope.showLoader();
      sharedService.changeAutoSharingStatus(data).then(
        function (res) {
          switch (res.data.code) {
            case CONST_HELPER.API_RESPONSE.OK:
              $scope.setSocialAccountsRootscope(
                res.data.data.role_id,
                res.data.data.social_accounts
              );
              $scope.hideLoader();
              break;
            case CONST_HELPER.API_RESPONSE.UNAUTHERIZED:
              $scope.hideLoader();
              $scope.logout(); // unauthorised user
              $state.go("signin");
              break;
          }
        },
        function () {
          $scope.hideLoader();
          $scope.showPopup("#serverErrModal");
        }
      );
    };
    $scope.emailVerificationAlert = function () {
      $rootScope.emailSent = false;
      if ($scope.user.is_email_verified === 0) {
        $(".alert-success").css("display", "block");
      }
    };
    // resend email verification link...
    $scope.resendEmailVerificationLink = function () {
      $scope.showLoader();
      var data = {
        email: $scope.user.email,
      };
      userRegisterService.resendEmailVerificationLink(data).then(
        function (res) {
          $rootScope.emailSent = true;
          $scope.hideLoader();
        },
        function () {
          $scope.hideLoader();
          $scope.showPopup("#serverErrModal");
        }
      );
    };
    // resend email verification link...
    $scope.updateYourEmail = function () {
      $state.go("consumer-profile");
      $(".alert-success").css("display", "none");
    };

    $scope.closeEmailVerificationAlert = function () {
      $(".alert-success").css("display", "none");
    };

    $rootScope.$on(
      "event:social-account-share",
      function (event, packageId, socialType) {
        var data = {
          package_id: packageId,
          social_type: socialType,
          token: $scope.user.token,
        };
        sharedService.addSharingCounter(data).then(
          function (res) {
            if (res.data.code === CONST_HELPER.API_RESPONSE.OK) {
            }
          },
          function () {}
        );
      }
    );

    $rootScope.isUserLoggedIn = function () {
      // check for logged in users
      if (typeof $scope.user.token === "undefined") {
        // cehck for user token
        $state.go("signin"); // redirect to home
        return false;
      } else {
        return true;
      }
    };
    $rootScope.showRightPanel = false;
    $scope.hideLeftPanel = function () {
      // check for logged in users
      if ($rootScope.showRightPanel) {
        $rootScope.showRightPanel = false;
      } else {
        $rootScope.showRightPanel = true;
      }
    };
    // $rootScope.isCustomCalendarFilter = false; //whether to show Complete custom filter dropdown
    $document.on("click", function (eve) {
      if (!$rootScope.isToolTipClick) {
        var nextEle = angular.element(".order-share-div");
        nextEle.addClass("hidden");
        nextEle.removeClass("active");
      }
      $rootScope.isToolTipClick = false;
      if (!$rootScope.isCustomCalendarClick) {
        // to close custom calendar on operator analytics page
        angular
          .element(document.querySelector(".analytics-dropdown"))
          .css("display", "none");
      }
      $rootScope.isCustomCalendarClick = false;
    });

    // set location length to 50 character
    $scope.setlocationlen = function (locationName, isradar) {
      if (isradar === "filter") {
        var location = locationName.split(",");
        return location[location.length - 1];
      }
      var len = locationName.length;
      var canLen = isradar === "radar" ? 30 : 50;
      if (len >= canLen) {
        return locationName.slice(0, canLen - 3) + "...";
      } else {
        return locationName;
      }
    };
    $scope.removePackageTag = function (event, $select) {
      $scope.hidePopup("#search-modal");
      event.stopPropagation();
      if ($select) {
        $rootScope.tag = {};
        $select.search = "";
        $select.selected = undefined;
      }
    };
    $scope.getSeoFriendlyURL = function (urlstr) {
      return $filter("seoFriendlyURL")(urlstr);
    };

    // check for expired slots...
    $scope.checkExpiredSlots = function (packageData) {
      packageData.map(function (packageList, keys) {
        packageList.hasActiveSlots = false;
        packageList.availableSeats = 0;
        packageList.activeSlots = 0;
        if (packageList.package_slots) {
          packageList.package_slots.map(function (slot, key) {
            var slotStartDate = new Date(slot.from_date);
            var currentDate = new Date();
            var diffDays = Math.floor(
              (Date.UTC(
                slotStartDate.getFullYear(),
                slotStartDate.getMonth(),
                slotStartDate.getDate()
              ) -
                Date.UTC(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  currentDate.getDate()
                )) /
                (1000 * 60 * 60 * 24)
            );
            if (diffDays < 0) {
              // if slot expired
              slot.expired = true;
            } else {
              slot.expired = false;
              packageList.hasActiveSlots = true;
              packageList.availableSeats += slot.available_seat; // get available seats of active stots.
              packageList.activeSlots = packageList.activeSlots + 1; // get package active slots...
            }
          });
        }
      });
    };

    // function to sort and format package discount data
    $scope.sortDiscountArray = function (
      package_discount,
      days,
      isNightly,
      packageCase
    ) {
      $scope.appliedNightlyDiscount = {};
      if (package_discount && package_discount.length) {
        if (packageCase == 2) {
          days = days + 1;
        }
        if (isNightly) {
          days = days - 1;
        }
        package_discount.sort(GetSortOrder("minimum_no_days"));
        for (var i = 0; i < package_discount.length; i++) {
          if (i != package_discount.length - CONST_HELPER.ONE) {
            // check for last array index
            package_discount[i].nextDiscount =
              package_discount[i + CONST_HELPER.ONE];
            days &&
            days >= package_discount[i].minimum_no_days &&
            days < package_discount[i].nextDiscount.minimum_no_days
              ? (package_discount[i].is_applied = true)
              : false;
          } else {
            days && days >= package_discount[i].minimum_no_days
              ? (package_discount[i].is_applied = true)
              : false;
          }
          if (package_discount[i].is_applied) {
            $scope.appliedNightlyDiscount = package_discount[i];
          }
        }
      }
    };

    function GetSortOrder(prop) {
      return function (a, b) {
        if (a[prop] > b[prop]) {
          return 1;
        } else if (a[prop] < b[prop]) {
          return -1;
        }
        return 0;
      };
    }

    // restrict user to enter .,e in number field
    $scope.numberKeyDown = function (e) {
      if ([69, 187, 188, 189, 190].includes(e.keyCode)) {
        e.preventDefault();
      }
    };

    $scope.myProfile = function () {
      $state.go("myprofile", {
        myusername: $rootScope.user.username,
      });
    };
    $scope.goOnAgent = function () {
      $state.go("operator-agent-analytics");
    };
    $rootScope.openMyNav = function () {
      // open left navigation panel
      if (!document.getElementById("mycustomSidenav")) {
        return;
      }
      document.getElementById("mycustomSidenav").style.width = "250px";
      document.getElementById("mycustomSidenav").style.marginLeft = "0";
      document.getElementById("myCanvasNav").style.display = "block";
      document.getElementById("myCanvasNav").style.width = "100%";
      document.getElementsByClassName("open-option")[0].style.display = "none";
      document.getElementsByClassName("close-option")[0].style.display =
        "block";
      document
        .getElementById("navigation-scroll")
        .classList.add("body-scroll-remove");
    };

    $rootScope.closeMyNav = function () {
      // close left navigation panel
      if (!document.getElementById("mycustomSidenav")) {
        return;
      }
      document.getElementById("mycustomSidenav").style.width = "0";
      document.getElementById("mycustomSidenav").style.marginLeft = "-250px";
      document.getElementById("myCanvasNav").style.display = "none";
      document.getElementById("myCanvasNav").style.width = "0";
      document.getElementsByClassName("close-option")[0].style.display = "none";
      document.getElementsByClassName("open-option")[0].style.display = "block";
      document
        .getElementById("navigation-scroll")
        .classList.remove("body-scroll-remove");
    };
    $interval(function () {
      // fetch new feed count
      if ($scope.user && $scope.userLoggedIn) {
        // if logged user only then look in for feed count
        $scope.getNewFeedsCount();
      }
    }, 30000);
    $scope.getNewFeedsCount = function () {
      // get new feeds for user
      var data = {
        token: $scope.user.token,
      };
      publicFeedsService.getNewFeedsCount(data).then(function (response) {
        if (
          response &&
          response.data &&
          response.data.code === CONST_HELPER.API_RESPONSE.OK
        ) {
          $rootScope.feedsCount = response.data.new_feed_count; // set feed count
          rootScopeService.setValue("feedsCount", $rootScope.feedsCount);
        }
      });
    };
    $scope.newPublicFeeds = function () {
      // broadcast to fetch new feeds
      $rootScope.feedsCount = 0; // reset feed count
      rootScopeService.setValue("feedsCount", $rootScope.feedsCount);
      $rootScope.$broadcast("newPublicFeeds");
    };
    $scope.goToPublicFeeds = function (name) {
      // get public feeds user reload scenario
      if ($rootScope.currentState.name === name) {
        $scope.newPublicFeeds();
      } else {
        $state.go(name);
      }
    };

    $scope.onMySiteClick = function () {
      if ($scope.user.on_my_site) {
        $state.go("on-my-site");
      } else {
      }
    };

    $scope.currencySymbol = {
      INR: "INR",
      USD: "USD",
      CAD: "CAD",
      EUR: "EUR",
      JPY: "JPY",
      GBP: "GBP",
      NZD: "NZD",
      AUD: "AUD",
      ISK: "ISK",
      MXN: "MXN",
      CHF: "CHF",
      BRL: "BRL",
    };
    // Routing Number length according to country
    $scope.routingLimit = {
      US: 9,
      GB: 6,
      CA: {
        transit_number: 5,
        institution_number: 3,
      },
      JP: {
        transit_number: 4,
        institution_number: 3,
      },
      AU: 6,
      BR: 7,
      NZ: 6,
      HK: 6,
      SG: 7,
      FR: 27,
      NO: 15,
      PT: 25,
      ES: 24,
      CH: 21,
      DE: 22,
    };
    // convertCurrency
    function getUserIP() {
      if ($scope.user && $rootScope.currentState.name !== "secure-checkout") {
        if ($scope.user.currency_code) {
          $rootScope.localCurrencyCode = $scope.user.currency_code;
          $rootScope.localCurrencySymbol =
            $scope.currencySymbol[$rootScope.localCurrencyCode];
          if (
            $rootScope.currentState.name === "package-slot-addons-summary" ||
            $rootScope.currentState.name === "adventure-detail" ||
            $rootScope.currentState.name === "adventure-detail-pre" ||
            $rootScope.currentState.name === "adventure-detail-private"
          ) {
            $rootScope.$broadcast("get-package-addons"); // now get package addons if page refresh in addons page
          }
          return false;
        }
      }
      $scope.showLoader();
      var ipapiUrl = "";
      if (!IPAPI_KEY) {
        ipapiUrl = "https://ipapi.co/json/";
      } else {
        ipapiUrl = "https://ipapi.co/json/?key=" + IPAPI_KEY;
      }
      $.ajax({
        type: "GET",
        url: ipapiUrl,
        success: function (res) {
          userAdventureDetailService.getCurrencyCode(res.ip, res.country).then(
            function (result) {
              var currencyDetails = result ? result.data.currency_details : {};

              if (currencyDetails.is_available === 1) {
                $rootScope.convertToLocalCurrency = true;
                $rootScope.localCurrencyCode = currencyDetails.currencyCode;
                $rootScope.localCurrencySymbol =
                  $scope.currencySymbol[$rootScope.localCurrencyCode];
              } else {
                $rootScope.convertToLocalCurrency = false;
                $rootScope.localCurrencyCode = "USD";
                $rootScope.localCurrencySymbol =
                  $scope.currencySymbol[$rootScope.localCurrencyCode];
              }
              $rootScope.currencyAlreadyFounded = true;
              $scope.hideLoader();
              if (
                $rootScope.currentState.name === "filter" ||
                $rootScope.currentState.name ===
                  "package-slot-addons-summary" ||
                $rootScope.currentState.name === "adventure-detail" ||
                $rootScope.currentState.name === "adventure-detail-pre" ||
                $rootScope.currentState.name === "adventure-detail-private"
              ) {
                $rootScope.$broadcast("get-package-addons"); // now get package addons if page refresh in addons page
              }
            },
            function () {
              $scope.hideLoader();
            }
          );
        },
        error: function (xhr, error) {
          $scope.hideLoader();
        },
      });
    }

    $scope.visiter = {
      fname: "",
      lname: "",
      email: "",
    };
    $scope.heliUpdateSignUp = function (form) {
      if (
        form.email.$pristine ||
        form.email.$invalid ||
        form.email.$viewValue.trim() === ""
      ) {
        // check for required validaiton of the email
        $scope.emailErr = true;
      } else {
        $scope.showLoader();
        $scope.emailErr = false;
        sharedService.subscribeHeliUpdate($scope.visiter).then(
          function (res) {
            if (res.data.code === CONST_HELPER.API_RESPONSE.OK) {
              $scope.visiterSubscribed = true;
              $scope.visiter = {
                fname: "",
                lname: "",
                email: "",
              };
            }
            $scope.hideLoader();
          },
          function () {
            $scope.hideLoader();
            $scope.showPopup("#serverErrModal");
          }
        );
      }
    };
    $scope.$watch("visiter.email", function (n, o) {
      if (n !== o) {
        $scope.emailErr = false;
      }
    });

    $scope.sortFilterMobile = function () {
      // for operator booking and payout history list mobile sorting
      $(".sort-filter-div").css("display", "block");
      $(".sort-filter-div").removeClass("hide-div");
    };

    $scope.closeSortFilterMobile = function () {
      // for operator booking and payout history list mobile sorting
      $(".sort-filter-div").addClass("hide-div");
    };

    angular.element($window).bind("scroll", function (e) {
      if (
        $rootScope.currentState.name === "new-route-white-label" &&
        this.pageYOffset > 100
      ) {
        var elemtn = $("div.iframe-silvertip-header");
        if (elemtn) {
          Object.keys(elemtn).forEach(function (key) {
            $(elemtn[key]).addClass("sm-header");
          });
          $("div.main-div").addClass("sm-page");
        }
      } else {
        var elemtn = $("div.iframe-silvertip-header");
        if (elemtn) {
          Object.keys(elemtn).forEach(function (key) {
            $(elemtn[key]).removeClass("sm-header");
          });
          $("div.main-div").removeClass("sm-page");
        }
      }
      if (
        this.pageYOffset > 500 &&
        $rootScope.currentState &&
        $rootScope.currentState.name === "home"
      ) {
        $rootScope.homeOffset = this.pageYOffset;
        return;
      }
      if (
        this.pageYOffset > 500 &&
        $rootScope.currentState &&
        $rootScope.currentState.name === "followers-listing"
      ) {
        $rootScope.followerListingOffset = this.pageYOffset;
        return;
      }
      if (
        this.pageYOffset > 500 &&
        $rootScope.currentState &&
        $rootScope.currentState.name === "following-listing"
      ) {
        $rootScope.followingListingOffset = this.pageYOffset;
      }
    });

    // function to copy the remaining payment link...
    $scope.copyRemainingPaymentLink = function (id) {
      var copyText = document.getElementById(id);
      copyText.select();
      document.execCommand("copy");
      showToast("Copied to clipboard.");
    };

    $scope.copyShareLinkLink = function (link) {
      if(
        (
          $rootScope.user.role_id == 2
          || $rootScope.user.role_id == 4
        )
        && $rootScope.user.commissions.referral_id
        && link?.length > 0
      ) {
        link = link.replace(/\/$/, "");
        link += '?referral_id=' + $rootScope.user.commissions.referral_id;
      }

      navigator.clipboard.writeText(link)
      showToast("Copied to clipboard.");
    };

    $scope.addToRadar = function (item) {
      var adventurePackage = {
        package_id: item.package_id,
        userToken: $scope.user.token
      };
      if ($rootScope.isUserLoggedIn()) {			// user logged in
        addToUserRadarList(adventurePackage, item);
      } else {
        saveCookie('addToRadarPackageId', adventurePackage.package_id);							// user not logged in
        $state.go('signin');
      }
    };

    function addToUserRadarList (adventurePackage, item) {
      $scope.showLoader();
      if (adventurePackage.package_id && adventurePackage.package_id !== 'null') {
        userAdventureDetailService.addToUserRadar(adventurePackage).then(function (res) {
          $scope.hideLoader();
          item.isRadar = 1;
          var data = {
            category: 'Package AddToRadar',
            action: 'Click',
            gtmLabel: 'Add To Radar'
          };
          $rootScope.$broadcast('addEventToGTM', data);
        }, function (_error) {
          $scope.hideLoader();
        });
      }
      $cookies.put('addToRadarPackageId', null);
    }

    // function to show toast after link is coppied...
    var showToast = function(msg) {
      var x = document.getElementById("snackbar");
      // Add the "show" class to DIV
      $("#snackbar").html(msg);

      x.className = "show";

      // After 3 seconds, remove the show class from DIV
      setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }

    // function to return the package currency rate of package currency vs local currency...
    $scope.getSlotUsdRate = function (from, to, callback) {
      if (from === to) {
        callback(CONST_HELPER.ONE);
      }
      userAdventureDetailService
        .convertCurrency(1, from, to)
        .then(function (ress) {
          callback(ress.data.usd_rate);
        });
    };

    function setCustomOnMySiteStyles() {
      var customStyles =
        "* {font-family: " +
        ($rootScope.pageDetails.body_font
          ? $rootScope.pageDetails.body_font.toString() + " !important"
          : "") +
        "}" +
        ".domain-heading { font-family: " +
        ($rootScope.pageDetails.head_font
          ? $rootScope.pageDetails.head_font.toString() + " !important"
          : "") +
        "}" +
        ".domain-btn { background-color: " +
        ($rootScope.pageDetails.button_color
          ? $rootScope.pageDetails.button_color.toString() + " !important;"
          : "") +
        "color: " +
        ($rootScope.pageDetails.button_text_color
          ? $rootScope.pageDetails.button_text_color.toString() + " !important"
          : "") +
        "}" +
        ".domain-btn:active, .domain-btn:focus, .domain-btn:hover { background-color: " +
        ($rootScope.pageDetails.button_color
          ? $rootScope.pageDetails.button_color.toString() + " !important;"
          : "") +
        "color: " +
        ($rootScope.pageDetails.button_text_color
          ? $rootScope.pageDetails.button_text_color.toString() + " !important"
          : "") +
        "}";
      $("<style>").text(customStyles).appendTo(document.head);
    }

    // function to active the featured tabs Adventure and ambassadors
    $scope.featuredClick = function (active) {
      $scope.isAmbassadorActive = active;
    };

    // function to get temp ids
    $scope.getTempId = function () {
      var s4 = function () {
        return Math.floor((CONST_HELPER.ONE + Math.random()) * 0x10000)
          .toString(16)
          .substring(CONST_HELPER.ONE);
      };
      var package_temp_id = package_temp_id || s4() + s4();
      return package_temp_id;
    };
    $scope.getOperatorPackageList = function (page) {
      //  get active package list function
      $scope.showLoader();
      $rootScope.loading = true;
      $rootScope.page = page;
      var hostName = $location.host();
      var requestObject = {
        user_name: hostName.slice(0, hostName.indexOf(".")),
        domain_url: $location.absUrl(),
        page: $rootScope.page,
        limit: CONST_HELPER.LIST.SIZE,
        back: CONST_HELPER.ZERO,
      };
      userSocialProfileService.operatorPackageListSubDomain(requestObject).then(
        function (response) {
          // call active package list api
          if (response && response.data.response) {
            $rootScope.loading = false;
            if (response.data.response.docs) {
              $scope.filteredAdventures = [];
              response.data.response.docs.forEach(function (adv) {
                if (adv.package_type == 1) {
                  $scope.filteredAdventures.push(adv);
                }
              });
            }
            if (response.data.response.docs && page > CONST_HELPER.ONE) {
              $rootScope.packageList = $rootScope.packageList.concat(
                $scope.filteredAdventures
              );
            } else {
              $rootScope.packageList = $scope.filteredAdventures;
            }

            $rootScope.pageDetails = response.data.response.pageDetails[0];
            if ($rootScope.pageDetails && !$rootScope.pageDetails.is_verified) {
              // Show Black page if not verified domain.
              $rootScope.blankPage = true;
            }
            $rootScope.pageDetails.header_tracking
              ? $("head").append($rootScope.pageDetails.header_tracking)
              : null;
            $rootScope.pageDetails.footer_tracking
              ? $("body").append($rootScope.pageDetails.footer_tracking)
              : null;
            rootScopeService.updateMetaTitle($rootScope.pageDetails.page_title); // set dynamic title and description of the page.
            rootScopeService.updateMetaDescription(
              $rootScope.pageDetails.page_desc
            );
            // TODO: GET FONT FROM WEB FONTS
            if (
              ($rootScope.pageDetails.head_font ||
                $rootScope.pageDetails.body_font) &&
              WebFont
            ) {
              WebFont.load({
                google: {
                  families: [
                    $rootScope.pageDetails.head_font,
                    $rootScope.pageDetails.body_font,
                  ],
                },
              });
              setCustomOnMySiteStyles();
            }
            $scope.hideLoader();
            $scope.checkExpiredSlots($rootScope.packageList);
            // more package Shown on adventure tab
            $scope.hasMorePackageToShow = function () {
              return listShown < response.data.response.numFound / listSize;
            };
          } else {
            $rootScope.blankPage = true;
          }
        },
        function (error) {
          $scope.hideLoader();
          $scope.showPopup("#serverErrModal");
        }
      );
    };

    $scope.callApiForOperatorProfile = function () {
      if (
        ($rootScope.currentState &&
          $rootScope.currentState.name == "new-route-white-label") ||
        $rootScope.isSubDomain
      ) {
        $scope && typeof $scope.getOperatorPackageList != "undefined"
          ? $scope.getOperatorPackageList(CONST_HELPER.ONE)
          : null;
      }
    };

    $scope.callApiForOperatorProfile();

    $scope.packageLimit = function () {
      // set package list limit here
      return listSize * listShown;
    };

    $scope.loadMorePackage = function () {
      // load more packages
      $scope.getOperatorPackageList($rootScope.page + CONST_HELPER.ONE);
      listShown = listShown + CONST_HELPER.ONE;
    };

    $rootScope.getOperatorOnMySiteDetail = function () {
      //  get active package list function
      $scope.showLoader();
      $rootScope.loading = true;
      var hostName = $location.host();
      var requestObject = {
        user_name: hostName.slice(0, hostName.indexOf(".")),
        domain_url: $location.absUrl(),
        page: CONST_HELPER.ONE,
        limit: CONST_HELPER.LIST.SIZE,
        back: CONST_HELPER.ZERO,
      };

      userSocialProfileService.operatorPackageListSubDomain(requestObject).then(
        function (response) {
          if (response && response.data && response.data.response) {
            $rootScope.loading = false;
            $rootScope.pageDetails = response.data.response.pageDetails[0];
            if ($rootScope.pageDetails && !$rootScope.pageDetails.is_verified) {
              // Show Black page if not verified domain.
              $scope.packageDataNotFound = true;
              $rootScope.disableHeaderBackBtn = true;
              rootScopeService.setValue(
                "disableHeaderBackBtn",
                $rootScope.disableHeaderBackBtn
              );
            }
            $rootScope.pageDetails.header_tracking
              ? $("head").append($rootScope.pageDetails.header_tracking)
              : null;
            $rootScope.pageDetails.footer_tracking
              ? $("body").append($rootScope.pageDetails.footer_tracking)
              : null;
            if (
              ($rootScope.pageDetails.head_font ||
                $rootScope.pageDetails.body_font) &&
              WebFont
            ) {
              WebFont.load({
                google: {
                  families: [
                    $rootScope.pageDetails.head_font,
                    $rootScope.pageDetails.body_font,
                  ],
                },
              });
              setCustomOnMySiteStyles();
            }
            $scope.hideLoader();
          } else {
            $scope.packageDataNotFound = true;
            $rootScope.disableHeaderBackBtn = true;
            rootScopeService.setValue(
              "disableHeaderBackBtn",
              $rootScope.disableHeaderBackBtn
            );
          }
        },
        function () {
          $scope.hideLoader();
          $scope.showPopup("#serverErrModal");
        }
      );
    };

    if ($rootScope.isSubDomain) {
      $rootScope.getOperatorOnMySiteDetail();
    }
  },
]);
// end of main controller...

window.mobileAndTabletcheck = function () {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};

// eslint-disable-next-line no-unused-vars
function setTwitterAuthData(user) {
  // twitter callback request after php login
  user = $.parseJSON(user);
  var userDetails = {
    name: user.name,
    username: user.screen_name,
    email: "",
    uid: user.twitter_id,
    provider: "twitter",
    imageUrl: user.profile_image_url,
    accessToken: "",
    twitter_oauth_token: user.access_token.oauth_token
      ? user.access_token.oauth_token
      : "",
    twitter_oauth_token_secret: user.access_token.oauth_token_secret
      ? user.access_token.oauth_token_secret
      : "",
  };
  var injector = angular.element(document.querySelector("[ng-app]")).injector();
  var $rootScope = injector.get("$rootScope");
  if ($rootScope.isConnect) {
    $rootScope.$broadcast("event:social-twitter-connect-success", userDetails);
  } else {
    $rootScope.$broadcast("event:social-sign-in-success", userDetails);
  }
}

// eslint-disable-next-line no-unused-vars
function setLinkedInAuthData(user) {
  var injector = angular.element(document.querySelector("[ng-app]")).injector();
  var $rootScope = injector.get("$rootScope");
  $rootScope.showLoaderRootScope();
  // eslint-disable-next-line no-unused-vars
  var sharedService = injector.get("sharedService");
  user = JSON.parse(user);
  var data = {
    linkedin_user_id: user.linkedin_id,
    uid: user.linkedin_id,
    linkedin_username: user.username ? user.username : user.firstName,
    linkedin_access_token: user.access_token,
    token: $rootScope.user ? $rootScope.user.token : "",
    email: user.emailAddress,
    imageUrl: user.pictureUrl ? user.pictureUrl : "",
    name: user.firstName + " " + user.lastName,
    linkedin_public_profile_url: user.publicProfileUrl,
  };
  if ($rootScope.isConnect) {
    $rootScope.$broadcast("event:social-linkedin-connect-success", data);
  } else {
    // for linkedin signup and signin
    data.provider = "linkedIN";
    $rootScope.hideLoaderRootScope();
    $rootScope.$broadcast("event:social-sign-in-success", data);
  }
}
