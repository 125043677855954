import { Component, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { UIRouter } from '@uirouter/core';

@Component({
  selector: 'app-additional-features-slider',
  templateUrl: '/ng-app/src/app/components/additional-features-slider/additional-features-slider.component.html',
  styleUrls: ['../../../../../ng-app/src/app/components/additional-features-slider/additional-features-slider.component.css']
})
export class AdditionalFeaturesSliderComponent implements AfterViewInit {

  banners = [
    //Earn credits
    {
      id: 1,
      image: 'https://heli-production.s3.amazonaws.com/images/slider/earn-credits.jpg',
      title: 'EARN CREDIT ON EVERY ADVENTURE',
      description: 'Earn even more when you bring your friends.',
      buttonText: 'Learn more',
      buttonLink: 'https://promo.heli.life/credits'
    },
      //Custom package
    {
      id: 2,
      image: 'https://heli-production.s3.amazonaws.com/images/slider/build-package.jpg',
      title: 'BUILD YOUR OWN PACKAGE',
      description: 'Don’t see it? We’ll create it.',
      buttonText: 'Get started',
      buttonLink: 'https://experts.heli.life'
    },
      //All trips
    {
      id: 3,
      image: 'https://heli-production.s3.amazonaws.com/images/slider/explore.jpg',
      title: 'EXPLORE OUR MOST PREMIUM EXPERIENCES',
      description: 'Adventures that are almost too good to be true.',
      buttonText: 'See trips',
      buttonLink: 'https://heli.black'
    },
  ];
  autoplay = window.innerWidth >= 640;

  slideConfig = {
    swipeToSlide: true,
    infinite: true,
    arrows: false,
    autoplay: this.autoplay,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };

  p: number = 1;
  collection: any[] = [1, 2, 3,4,5,6,7,8,9,0,12]; 

  @ViewChild('carousel') carousel;

  constructor(
    @Inject(UIRouter) public router: UIRouter,
  ) { }

  ngAfterViewInit() {
    this.initWithRandomIndex();
  }

  goToPage(routerLink) {
    if (!routerLink) { return; }
    this.router.stateService.go(routerLink.page, routerLink.query);
  }

  initWithRandomIndex() {
    const index = Math.floor(Math.random() * this.carousel.slides.length);
    this.carousel.slickGoTo(index);
  }

}
