import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-adventures-list',
  templateUrl: '/ng-app/src/app/components/header/navbar/adventures-list/adventures-list.component.html',
  styleUrls: ['../../../../../../../ng-app/src/app/components/header/navbar/adventures-list/adventures-list.component.css']
})
export class AdventuresListComponent implements OnInit {

  @Input() adventures: any = [];
  @Input() goToSelectedCategory;

  constructor() { }

  ngOnInit(): void {
  }

}
