import { Component, OnInit, Inject, Injectable} from '@angular/core';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';
import { helper } from '../../../../../app/scripts/helper';
import { UserAdventureDetailService } from '../../../../../app/scripts/services/userAdventureDetailService';
import { SearchService } from '../../../../../app/scripts/services/searchService';
import { RootScopeService } from '../../services/root-scope/root-scope.service';

@Injectable({
  providedIn: 'root'
})
export class LoadAdventuresService {

  defaultFilter: any = {
    abilities: [
      { name: 'Beginner', isChecked: false, id: 1 },
      { name: 'Intermediate', isChecked: false, id: 2 },
      { name: 'Expert', isChecked: false, id: 3 },
      { name: 'Legendary', isChecked: false, id: 4 }
    ],
    abilitie_id: [],
    activities: [],
    activity_id: '',
    back: 0,
    days: 0,
    featuredActivityId: '',
    keyword: '',
    lang: '',
    lat: '',
    limit: 20,
    location: '',
    max_price: '',
    min_price: '',
    maxdate: '',
    mindate: '',
    package_sports_id: '',
    page: 1,
    people: 0,
    sort: "",
    sortingKey: "",
    sortingReverse: false
  };
  selectedActivity: any;
  resultFound = 0;
  activityData: any;
  searchData: any = [];
  featuredActivity: any = {
    'ski': [1, 2, 3, 4],
    'kite': [5, 6, 37, 39, 38],
    'surf': [8, 7, 40, 41],
    'fish': [43, 10, 9, 42],
    'bike': [13, 14],
    'dive': [11, 12],
    'safari': [22, 20, 48]
  };
  noDataFoundAfterFilter: any = false;
  noDataFoundAfterSearch: any = false;
  localCurrencyCode: any;
  filtersApplied: any = [];
  appliedFilters: any = [];
  filter: any;
  is_filter_adventures: any = false;

  constructor(
    @Inject(SearchService) private searchService: SearchService,
    @Inject(UserAdventureDetailService) private userAdventureDetailService: UserAdventureDetailService,
    @Inject(RootScopeService) public rootScopeService: RootScopeService,
    @Inject("$rootScope") private rootScopeJS: any,
    public router: UIRouter,
    public routerGlobals: UIRouterGlobals
  ) { }

  setDefaultFilter(pageName: any = null, pageNumber: number = 1) {
    this.filtersApplied = [];
    this.filter = { ...this.defaultFilter };
    this.localCurrencyCode = this.rootScopeJS.localCurrencyCode ? this.rootScopeJS.localCurrencyCode : 'USD';
    switch (pageName) {
      case 'filter':
        let filterParams = {...this.routerGlobals.params};
        Object.keys(filterParams).forEach(key => {
          if (filterParams[key] && key == 'activity_id') {
            let array = filterParams[key].split(',');
            array.forEach((id,index) => {array[index] = Number(id)});
            this.filter[key] = this.filter['featuredActivityId'] = array;
          } else if (key !== 'activities') {
            filterParams[key] && (this.filter[key] = filterParams[key]);
          }
        });
        console.log('!!!!!!!', this.filter)
        // this.filter['page'] = parseInt(this.filter['page']);
        this.filter['page'] = parseInt(filterParams.page);
        if (this.filter['activity_id'] || this.filter['min_price'] || this.filter['max_price'] || this.filter['duration'] || this.filter['people']) {
          this.applyFilters(false, 1);
        } else {
          this.loadAdventure();
        }
        break;
      case 'activity-categories':
        console.log("case 'activity-categories'", this.routerGlobals.params)
        this.selectedActivity = this.routerGlobals.params.activityCategory;
        this.activityData = helper.FEATURED_ACTIVITY.find((obj) => {
          return obj.slug.toLowerCase() === this.selectedActivity.toLowerCase();
        });
        this.rootScopeService.updateMetaTitle(this.activityData.title);
        this.filtersApplied.push({'type':'activity','name':this.selectedActivity});
        this.filter.package_sports_id = this.activityData.id;
        this.filter.activity_id = this.filter.featuredActivityId = this.featuredActivity[this.routerGlobals.params.activityCategory];
        this.filter.page = this.routerGlobals.params.page;
        break;
      default :
        console.log('not found page')
        break;
    }
  }

  applyFilters(loadMore, page: number = null) {
    this.filter.page = page ? page : 1;
    // !loadMore && (this.filter.page = 1);
    document.getElementById('filter-head').classList.add('toggled'); // hide sidebar-filter
    this.rootScopeJS.showLoaderRootScope();
    this.createTag(); //  create tags for price range , days and people.
    this.is_filter_adventures = true;
    this.searchService.filterAdventures(this.filter).then((adv: any) => {
      this.setAdventureData(adv, false, false, this.filter.page);
    }, err => {
      this.errorLoadingData();
    });
  };

  loadAdventure(loadMore: boolean = false) {
    this.rootScopeJS.showLoaderRootScope();
    this.is_filter_adventures = false;
    this.searchService.searchAdventures(this.filter).then(adv => {
      if (adv) {
        this.setAdventureData(adv, loadMore, true, this.filter.page);
      }
    }, (err) => {
      this.errorLoadingData();
    });
  }

  sortAdventure() {
    this.rootScopeJS.showLoaderRootScope();
    this.searchService.sortData(this.filter).then(adv => {
      this.setAdventureData(adv, false, false, this.filter.page);
    }, (err) => {
      this.errorLoadingData();
    });
  };

  updateAdventures(loadMore: boolean = false, page = 1) {
    if (this.filtersApplied.length || this.is_filter_adventures) {
      this.applyFilters(loadMore, page);
    } else if (this.filter.sort) {
      this.sortAdventure();
    } else {
      this.loadAdventure(loadMore);
    }
  }

  setAdventureData(adv, loadMore: boolean = false, suggestedAdventures: boolean = false, page = 1) {
    if (adv.data?.code == 400) { 
      this.noDataFoundAfterSearch = this.noDataFoundAfterFilter = true;
      this.rootScopeJS.hideLoaderRootScope();
      return; 
    }
    let adventuresData = adv['data'].response;
    this.appliedFilters = this.filtersApplied.slice();
    this.resultFound = adventuresData.numFound;
    this.searchData = !loadMore ? adventuresData.docs : this.searchData.concat(adventuresData.docs);
    console.log('in setAdventureData', this.searchData)
    if (this.searchData.length) {
      this.noDataFoundAfterSearch = this.noDataFoundAfterFilter = false;
      this.getFeaturedAdventureData(this.searchData);
    } else if (suggestedAdventures) {
      this.noDataFoundAfterSearch = true;
      this.getAdventuresSuggestion(this.filter);
    } else {
      this.noDataFoundAfterSearch = this.noDataFoundAfterFilter = true;
      this.rootScopeJS.hideLoaderRootScope();
    }
  }

  getAdventuresSuggestion(filter) {
    this.searchService.getAdventureSuggestion(filter).then(res => {
      let adventuresSuggestedData = res['data'].response ? res['data'].response.docs : [];
      this.getFeaturedAdventureData(adventuresSuggestedData);
      this.searchData = adventuresSuggestedData.slice();
    }, err => {
      this.errorLoadingData();
    });
  }

  getFeaturedAdventureData(searchData) {
    searchData.forEach((adventure, key) => {
      this.getLocalCurrencyRate(searchData, adventure, key);
      this.checkExpiredSlot(adventure, key);
      this.getAdventureAvailableSeats(adventure);
    });
    this.rootScopeJS.hideLoaderRootScope();
  }

  getLocalCurrencyRate(searchData, adventure, key) {
    adventure.localCurrencyRate = 1;
    if (adventure.currency_code && adventure.currency_code[0] !== this.localCurrencyCode) {
      this.userAdventureDetailService.convertCurrency(1, adventure.currency_code[0], this.localCurrencyCode).then(res => {
        adventure.localCurrencyRate = res['data'].usd_rate;
        searchData.length === ++key && this.sortByPrice(this.filter.sort);
      });
    }
    adventure.convertedPrice = adventure.min_price * adventure.localCurrencyRate;
    adventure.packageCurrencyCode = this.localCurrencyCode;
    adventure.startDate = new Date(adventure.min * 1000);
    adventure.medias?.forEach(img => {
      if (img?.media_type === 0 && img?.is_main === 1) { // can be 0,1,2
        adventure.featuredImg = img.url ? img.url : 'images/img2.png';
      }
    });
  }

  checkExpiredSlot(adventure, key) {
    adventure.hasActiveSlots = false;
    adventure.availableSeats = adventure.activeSlots = 0;
    adventure.package_slots?.forEach((slot) => {
      let slotStartDate = new Date(slot.from_date);
      let currentDate = new Date();
      let diffDays = Math.floor((Date.UTC(slotStartDate.getFullYear(), slotStartDate.getMonth(), slotStartDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
      if (diffDays < 0) { // if slot expired
        slot.expired = true;
      } else {
        slot.expired = false;
        adventure.hasActiveSlots = true;
        adventure.availableSeats += slot.available_seat;
        adventure.activeSlots += 1;
      }
    });
  }

  getAdventureAvailableSeats(adventure) {
    adventure.booking_type?.slice().shift() == 2 && (adventure.availableSeats = adventure.total_nightly_available_days[0]);
  }

  sortByPrice(sortby) {
    // handle price sorting in front end using orderby
    switch (sortby) {
      case 'min_price-asc':
        this.filter.sortingKey = 'convertedPrice';
        this.filter.sortingReverse = false;
        break;
      case 'min_price-desc':
        this.filter.sortingKey = 'convertedPrice';
        this.filter.sortingReverse = true;
        break;
      default :
        this.filter.sortingKey = '';
        this.filter.sortingReverse = false;
        break;
    }
  }

  createTag() {
    let filterTags = [
      { name: this.filter.days + ' Days', type: 'day', selector: ['days'] },
      { name: this.filter.people + ' people', type: 'people', selector: ['people'] },
      { name: ' $' + (this.filter.min_price || 1) + ' - ' + this.filter.max_price, type: 'price', selector: ['max_price','min_price'] }
    ];
    filterTags.forEach(tag => {
      let index = this.filtersApplied.findIndex(filter => filter.type === tag.type);
      if (index > 0) {
        let i = this.filtersApplied.indexOf(index);
        this.filter[tag.selector[0]] > 0 ? this.filtersApplied[i] = tag : this.filtersApplied.splice(i, 1);
      } else {
        if(!this.filter[tag.selector[0]]) return;
        this.filtersApplied.push(tag);
        tag.selector.length > 1 && (this.filter.min_price = this.filter.min_price ? this.filter.min_price : 1);
      }
    });
    this.filter['page'] = parseInt(this.filter['page']);
  }

  removeRouteDash(route) {
    let correctString = '';
    [...route].forEach(letter => {
      correctString += letter == '-' ? ' ' : letter == ' ' ? '-' : letter.toLowerCase();
    });
    return correctString;
  }

  errorLoadingData() {
    this.rootScopeJS.hideLoaderRootScope();
    this.rootScopeService.showPopup('#serverErrModal');
  }
}
