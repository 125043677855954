import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { downgradeComponent, } from '@angular/upgrade/static';
import { UIRouter } from '@uirouter/core';
import * as angular from 'angular';
import { SearchService } from '../../../../../app/scripts/services/searchService';
import { CookieService } from 'ngx-cookie-service';
import { RootScopeService } from '../../services/root-scope/root-scope.service';
import { UserLoginService } from '../../../../../app/scripts/services/userLoginService';
import { helper } from '../../../../../app/scripts/helper';
import { messages } from '../../../../../app/scripts/messages';
import { LoaderService } from '../../services/loader/loader.service';
import { UserAdventureDetailService } from '../../../../../app/scripts/services/userAdventureDetailService';
import { OperatorService } from '../../../../../app/scripts/services/operatorService';
import { UserRegisterService } from '../../../../../app/scripts/services/userRegisterService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SearchServiceService } from '../../services/search-service/search-service.service';
import { WebService } from '../../../../../app/scripts/services/webService';
import { CreateAdventureService } from '../../../../../app/scripts/services/operatorCreateAdventureService';
import { LoadAdventuresService } from '../../services/load-adventures/load-adventures.service';

@Component({
  selector: 'header-page',
  templateUrl: '/ng-app/src/app/components/header/header.component.html',
  styleUrls: ['../../../../../ng-app/src/app/components/header/header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('select', { static: false }) select: ElementRef;

  form: FormGroup
  openOverlay: boolean = false;
  currencySymbol = {
    'INR': 'INR',
    'USD': 'USD',
    'CAD': 'CAD',
    'EUR': 'EUR',
    'JPY': 'JPY',
    'GBP': 'GBP',
    'NZD': 'NZD',
    'AUD': 'AUD',
    'ISK': 'ISK',
    'MXN': 'MXN',
    'CHF': 'CHF',
    'BRL': 'BRL'
  };
  openedSearchPanel: boolean = false;
  displayedSubHeaderItems: any = [];
  currentTab: string = '';
  previousTab: any = '';
  adventuresList: any = [];
  noSportPage = ['raft', 'kayak','hike','climb','jump','fly','moto','wake','shoot','wellness','event','other'];
  destinationsList: any = [];

  MESSAGE_HELPER = messages;
  const_helper = helper;
  oldPasswordErr: boolean;
  changePasswordErr: boolean;
  changePasswordModel: any;
  changeConfirmPassErr: boolean;
  showErrorSamePassword: boolean;
  oldPassNotMatch: boolean;
  oldPassNotMatchErr: any;
  changePasswordServerErr: boolean;
  changePwdServerErrMessage: any;
  isSuccessfull: boolean;
  currentMousePosition: any;

  constructor(
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    @Inject(RootScopeService) public rootScopeService: RootScopeService,
    @Inject(UIRouter) public router: UIRouter,
    @Inject(CookieService) private cookies: CookieService,
    @Inject(SearchService) private searchService: SearchService,
    @Inject("$rootScope") public rootScope: any,
    @Inject(UserLoginService) private userLoginService: UserLoginService,
    @Inject(LoaderService) private loaderService: LoaderService,
    @Inject(UserAdventureDetailService) private userAdventureDetailService: UserAdventureDetailService,
    @Inject(OperatorService) private operatorService: OperatorService,
    @Inject(UserRegisterService) private userRegisterService: UserRegisterService,
    @Inject(SearchServiceService) public searchServiceService: SearchServiceService,
    @Inject(CreateAdventureService) public createAdventureService: CreateAdventureService,
    @Inject(WebService) public webService: WebService,
    private loadAdventures: LoadAdventuresService
  ) {
    this.getAdventures();
    this.getDestinationCategories();
  }

  ngOnInit(): void {
    this.initForm();
    this.router.locationService.onChange((detect) => {
      this.closeMobileHeader();
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      oldpass: ['', Validators.compose([Validators.required])],
      changePassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      changeConfirmPassword: ['', Validators.compose([Validators.required])]
    }, {
      validator: this.mustMatch('changePassword', 'changeConfirmPassword')
    });
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  getDestinationCategories() {
    this.webService.getDestinationCategories().then(res=>{
      this.destinationsList = res['data'];
      console.log(this.destinationsList)
    })
  }

  getAdventures() {
    this.createAdventureService.getSportsList().then(adventures => {
      if (adventures['data']['sports'].length) {
        adventures['data']['sports'].forEach(sport => {
          if (sport.sport_name !== 'Shoot') {
            this.adventuresList.push({
              title: sport.sport_name,
              id: sport.id,
              image: sport.image_url,
              slug: sport.slug,
              categories: sport.categories
            })
          }
        });
      } else {
        this.rootScope.hideLoaderRootScope();
        this.rootScopeService.showPopup('#serverErrModal');
      }
    })
  }

  openNav() {
    document.getElementById('Navbar-Sidenav').style.minWidth = '300px';
    document.getElementById('Navbar-Sidenav').style.right = '0';
  }

  closeNav() {
    document.getElementById('Navbar-Sidenav') && (document.getElementById('Navbar-Sidenav').style.right = '-100%');
  }

  setWhiteLabelPreviousState() {
    window.history.back();
  };

  closeEmailVerificationAlert() {
    $('.alert-success').css('display', 'none');
  };

  updateYourEmail() {
    this.router.stateService.go('consumer-profile');
    $('.alert-success').css('display', 'none');
  };

  resendEmailVerificationLink() {
    this.loaderService.showLoader();
    const data = {
      email: this.rootScope.user.email
    };
    this.userRegisterService.resendEmailVerificationLink(data).then((res) => {
      this.rootScope.emailSent = true;
      this.loaderService.hideLoader();
    }, () => {
      this.loaderService.hideLoader();
      this.showPopup('#serverErrModal');
    });
  };

  goToRadar(isUserLoggedIn) { // radar redirection
    if (isUserLoggedIn) {
      this.router.stateService.go('consumer-radar');
    } else {
      this.router.stateService.go('signin');
      this.cookies.set('goToRadar', JSON.stringify(true));
    }
  }

  myProfile() {
    this.router.stateService.go('myprofile', {
      myusername: this.rootScopeService.rootScope.user.username
    });
  }

  myCredits() {
    this.router.stateService.go('consumer-profile', {
      myusername: this.rootScopeService.rootScope.user.username
    });
    this.cookies.set('goToCredits', JSON.stringify(true));
  }

  newPublicFeeds() { // broadcast to fetch new feeds
    this.rootScopeService.rootScope.feedsCount = 0; // reset feed count
    this.rootScope.$broadcast('newPublicFeeds');
  };

  goToPublicFeeds(name) { // get public feeds user reload scenario
    if (this.router.stateService.is(name)) {
      this.newPublicFeeds();
    } else {
      this.router.stateService.go(name);
    }
  };

  logout() { // logout user from heli
    this.userLoginService.logOutUser(this.rootScopeService.rootScope.user.token).then((response: any) => {
      const cookies = this.cookies.getAll();
      var couponData = {
        promo_code_id: this.cookies.get('coupon_id'),
        cart_id: this.cookies.get('cart_id'),
        previousPrice: '' // static cart total.
      };
      angular.forEach(cookies, (v, k) => {
        this.cookies.delete(k);
      }); // clear local storage
      // var domainName = window.location.host.substr(window.location.host.indexOf('.'));
      (() => {
        var cookies = document.cookie.split('; ');
        for (var c = 0; c < cookies.length; c++) {
          var d = window.location.hostname.split('.');
          while (d.length > 0) {
            var cookieBase = encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
            var p = location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
              document.cookie = cookieBase + p.join('/');
              p.pop();
            };
            d.shift();
          }
        }
      })();
      this.rootScopeService.rootScope.userLoggedIn = false; // rest scope
      this.rootScopeService.rootScope.hasProfile = false; // rest scope
      // this.getUserDetails(); // reset scope
      this.rootScopeService.rootScope.userRole = 'user';
      this.rootScopeService.rootScope.myCartLength = helper.ZERO;
      this.rootScopeService.rootScope.user = {};
      // dont redirect user to home page...
      if (this.router.stateService.is('filter') || this.router.stateService.is('adventure-detail')) {
        // do nothing when user logout...
        this.rootScope.$broadcast('user.logout', couponData);
      } else {
        this.rootScope.$broadcast('user.logout', couponData);
        if (!this.rootScopeService.rootScope.siteInIFrame) {
          this.router.stateService.go('home');
        } // redirect to home page
        if (this.rootScopeService.rootScope.isAgent) {
          this.router.stateService.go('signin');
        }
      }
      this.getUserIP();
      this.closeNav();
    }, () => { });
  };

  getUserIP() {
    if (this.rootScope.user && this.rootScope.currentState.name !== 'secure-checkout') {
      if (this.rootScope.user.currency_code) {
        this.rootScope.localCurrencyCode = this.rootScope.user.currency_code;
        this.rootScope.localCurrencySymbol = this.currencySymbol[this.rootScope.localCurrencyCode];
        if (this.rootScope.currentState.name === 'package-slot-addons-summary' || this.rootScope.currentState.name === 'adventure-detail' || this.rootScope.currentState.name === 'adventure-detail-pre' || this.rootScope.currentState.name === 'adventure-detail-private') {
          this.rootScope.$broadcast('get-package-addons'); // now get package addons if page refresh in addons page
        }
        return false;
      }
    }
    this.loaderService.showLoader();
    // var ipapiUrl = '';
    // if (!IPAPI_KEY) {
    //   ipapiUrl = 'https://ipapi.co/json/';
    // } else {
    //   ipapiUrl = 'https://ipapi.co/json/?key=' + IPAPI_KEY;
    // }
    $.ajax({
      type: 'GET',
      url: 'https://ipapi.co/json/',
      success: (res) => {
        this.userAdventureDetailService.getCurrencyCode(res.ip, res.country).then((result: any) => {
          var currencyDetails = result ? result.data.currency_details : {};

          if (currencyDetails.is_available === 1) {
            this.rootScope.convertToLocalCurrency = true;
            this.rootScope.localCurrencyCode = currencyDetails.currencyCode;
            this.rootScope.localCurrencySymbol = this.currencySymbol[this.rootScope.localCurrencyCode];
          } else {
            this.rootScope.convertToLocalCurrency = false;
            this.rootScope.localCurrencyCode = 'USD';
            this.rootScope.localCurrencySymbol = this.currencySymbol[this.rootScope.localCurrencyCode];
          }
          this.rootScope.currencyAlreadyFounded = true;
          this.loaderService.hideLoader();
          if (this.rootScope.currentState.name === 'filter' || this.rootScope.currentState.name === 'package-slot-addons-summary' || this.rootScope.currentState.name === 'adventure-detail' || this.rootScope.currentState.name === 'adventure-detail-pre' || this.rootScope.currentState.name === 'adventure-detail-private') {
            this.rootScope.$broadcast('get-package-addons'); // now get package addons if page refresh in addons page
          }
        }, () => {
          this.loaderService.hideLoader();
        });
      },
      error: (xhr, error) => {
        this.loaderService.hideLoader();
      }
    });
  };

  initializeValue(param?) {
    switch (param) {
      case 'old': // ng-keyup event on old password text
        this.oldPassNotMatch = false;
        break;
      case 'confirm': // ng-keyup event on confirm password text
        this.changeConfirmPassErr = false;
        this.changePasswordServerErr = false;
        break;
      case 'close': // its when success message is shown
        this.form.reset();
        this.oldPasswordErr = false;
        this.oldPassNotMatch = false;
        this.changePasswordErr = false;
        this.changePasswordServerErr = false;
        this.changeConfirmPassErr = false;
        break;
      case 'new':
        this.changePasswordErr = false;
        this.form.controls.changePassword.markAsDirty({ onlySelf: true });
        if (this.form.controls.oldpass.value == this.form.controls.changePassword.value) {
          this.showErrorSamePassword = true;
        } else {
          this.showErrorSamePassword = false;
        }
        break;
      default: // when change password modal is closed or opened
        this.isSuccessfull = false;
        this.form.reset();
        let form = this.form.controls;//
        // dirty check the user has interacted with the form.closing the modal when there were errors on the form were not
        // seting the dirty property to false
        form.changePassword.markAsDirty({ onlySelf: false });
        form.changeConfirmPassword.markAsPristine({ onlySelf: true });
        // value of new password text field were not getting cleared, when modal is closed with red errors on it
        $('#changePassword').val(null);
        this.oldPasswordErr = false;
        this.oldPassNotMatch = false;
        this.changePasswordErr = false;
        this.changePasswordServerErr = false;
        this.changeConfirmPassErr = false;
        break;
    }
  };

  changePassword(form: { oldpass: string, changePassword: string, changeConfirmPassword: string }) {
    this.oldPasswordErr = (this.form.controls.oldpass.pristine) ? true : ((this.form.controls.oldpass.value) ? (((this.form.controls.oldpass.value).trim() === '')) : true);
    this.changePasswordErr = (this.form.controls.changePassword.pristine) ? true : ((this.form.controls.changePassword.value) ? (((this.form.controls.changePassword.value).trim() === '')) : true);

    if (this.form.controls.changePassword.value && (this.form.controls.changePassword.value).trim() === '') {
      this.changePasswordErr = true; // password is blank
    }

    if (!this.changePasswordErr) {
      this.changeConfirmPassErr = (this.form.controls.changeConfirmPassword.pristine) ? true : ((this.form.controls.changeConfirmPassword.value) ? (!!(((this.form.controls.changeConfirmPassword.value).trim() === '' || ((this.form.controls.changePassword.value).trim() !== (this.form.controls.changeConfirmPassword.value).trim())))) : true);
    }
    if (this.form.controls.oldpass.value == this.form.controls.changePassword.value) {
      this.showErrorSamePassword = true;
    }

    var data = {
      'token': this.rootScope.user.token,
      'old_password': form.oldpass,
      'new_password': form.changeConfirmPassword
    };

    if (!this.changePasswordErr && !this.oldPasswordErr && !this.changeConfirmPassErr && !this.showErrorSamePassword) {
      this.rootScope.showLoaderRootScope();
      this.operatorService.changeOperatorPass(data).then((response: any) => { // post to the server the new  password
        if (response.data) {
          if (response.data.code !== helper.API_RESPONSE.OK) { // some error exits
            switch (response.data.code) {
              case helper.API_RESPONSE.UNPROCESSABLE:
                this.oldPassNotMatch = true;
                this.oldPassNotMatchErr = response.data.message;
                break;
              case helper.API_RESPONSE.BAD_REQUEST:
                this.changePasswordServerErr = true; // set error status
                this.changePwdServerErrMessage = response.data.message;
                break;
              default:
                this.changePasswordServerErr = true; // set error status
                this.changePwdServerErrMessage = response.data.message;
                // for (var key in response.data.message[Object.keys(response.data.message)]) {
                //     this.changePwdServerErrMessage = response.data.message[Object.keys(response.data.message)][key];
                // }
                break;
            }
            this.rootScope.hideLoaderRootScope();
          } else {
            this.initializeValue('close'); // reset the change password modal
            this.isSuccessfull = true;
            this.rootScope.hideLoaderRootScope();
          }
        }
      }, (error) => {
        this.rootScope.hideLoaderRootScope();
        this.showPopup('#serverErrModal');
      });
    }
  };

  showPopup(modalId) { // show the popup
    $(modalId).modal('show');
  };

  hidePopup(modalId) { // hide the hide
    $(modalId).modal('hide');
    // document.getElementsByTagName('body')[0].classList.remove('modal-open');
    document.getElementsByTagName('body')[0].className.replace(' modal-open ', ' ');
    $('body').css('padding-right', '0px');
    if (angular.element(document.querySelector('.modal-backdrop')).length) {
      var el = document.getElementsByClassName('modal-backdrop')[0];
      // document.getElementsByClassName("modal-backdrop")[0].remove();
      el.parentNode.removeChild(el);
    }
  };

  checkSavedCard() { // check if card is being saved
    if (this.rootScope.currentState.name === 'consumer-profile') {
      this.rootScope.$broadcast('card-saved'); // broadcast for card saving
    }
  };

  closeModal() {
    $('body').removeClass('fix-scroll');
    var data = {
      category: 'User Search',
      action: 'Click',
      gtmLabel: 'Search'
    };
    this.rootScope.$broadcast('addEventToGTM', data);
    this.hidePopup('#search-modal');
  }

  checkMousePosition(event) {
    this.currentMousePosition = event;
  }

  openSubMenu(tabName) {
    setTimeout(() => {
      if (tabName == 'adventures' || tabName == 'destinations') {
        this.lockScroll(true);
        this.currentTab = tabName;
        this.previousTab = {title: tabName}
        this.displayedSubHeaderItems = this.currentTab == 'adventures' ? this.adventuresList : this.destinationsList;
        this.openedSearchPanel = false;
      } else {
        this.displayedSubHeaderItems = tabName.categories ? tabName.categories : tabName.destination_pages;
        this.previousTab = tabName;
      }
    }, 0);
  }

  selectedActivity(activity) {
    if (!activity.categories && !activity.destination_pages) {
      this.goToSelectedCategory(this.previousTab, activity);
    } else {
      this.goToSelectedCategory(activity, null);
    }
  }

  toPreviousCategory(categoryName) {
    if (categoryName == 'adventures' || categoryName == 'destinations') {
      this.previousTab = '';
      this.displayedSubHeaderItems = [];
    } else {
      if (this.previousTab.categories) {
        this.previousTab = {title: 'adventures'};
        this.displayedSubHeaderItems = this.adventuresList;
      } else {
        this.previousTab = {title: 'destinations'};
        this.displayedSubHeaderItems = this.destinationsList;
      }
    }
  }

  openSearchPanel() {
    this.openedSearchPanel = !this.openedSearchPanel;
    this.lockScroll(this.openedSearchPanel);
    if (this.openedSearchPanel) {
      setTimeout(() => {
        this.searchServiceService.onSearch();
      }, 0);
    }
  }

  lockScroll(lock: boolean = false) {
    document.body.style.overflow = lock ? 'hidden' : 'auto';
  }

  closeAllHeaderModal(event: any = null, redirect: boolean = false) {
    this.currentMousePosition = event;
    setTimeout(() => {
      if (!this.currentMousePosition || redirect || (this.currentMousePosition.type == 'mouseenter' && this.currentMousePosition.target && this.currentMousePosition.target.id !== 'subheader' && this.currentMousePosition.target.id !== 'adventure-tab' && this.currentMousePosition.target.id !== 'destination-tab')
          || (this.currentMousePosition.type == 'mouseleave' && this.currentMousePosition.target && (this.currentMousePosition.target.id == 'subheader' || this.currentMousePosition.target.id == 'adventure-tab' || this.currentMousePosition.target.id == 'destination-tab'))) {
        this.currentTab = '';
        this.openedSearchPanel = false;
        this.lockScroll(false);
      }
    }, 300);
  }

  goToSelectedCategory(category: any, option: any) {
    let categoryName = '';
    let locationName = '';
    let route = '';
    let routeData: any = {};
    this.loadAdventures.setDefaultFilter();
    this.loadAdventures.searchData = [];
    if (category?.slug) {
      categoryName = category?.slug;
    } else {
      categoryName = category?.title.replaceAll(' ', '-').toLowerCase();
    }
    // categoryName = category?.title.replaceAll(' ', '-').toLowerCase();
    // if (this.noSportPage.some(sportName => sportName == categoryName) && !option) {
    //   option = {id: ''};
    //   category.categories.forEach((sportCategory,index) => {
    //     option.id += category.categories.length-1 !== index ? `${sportCategory.id},` : sportCategory.id
    //   });
    // }
    if (category?.destination_pages && option) {
      //Destination location page
      locationName = option.name.replaceAll(' ', '-').toLowerCase();
      route = 'destination-location';
      routeData['destinationCategory'] = categoryName;
      routeData['destinationLocation'] = locationName;
    } else if (category?.destination_pages) {
      //Destination activity page
      route = 'destination-categories';
      routeData['destinationCategory'] = categoryName;
    } else if (option) {
      //Adventure page with option
      route = 'filter';
      category?.title && (routeData['activities'] = category?.title);
      option?.id && (routeData['activity_id'] = option?.id);
      routeData['page'] = 1;
      routeData['keyword'] = "";
      routeData['lang'] = "";
      routeData['lat'] = "";
      routeData['limit'] = 2;
      routeData['location'] = "";
      routeData['max_price'] = "";
      routeData['min_price'] = "";
      routeData['maxdate'] = "";
      routeData['mindate'] = "";
      routeData['sort'] = "";
    } else {
      //Adventure page from navbar menu
      route = 'activity-categories';
      routeData.activityCategory = categoryName;
      routeData.page = 1;
      console.log('write activityCategory in routeData', routeData)
    }
    routeData['page'] = 1;
    console.log('routeData',routeData, 'route', route)
    this.router.stateService.go(route, routeData, { reload: true });
  }

  closeMobileHeader() {
    this.displayedSubHeaderItems = [];
    this.closeAllHeaderModal(null, true);
    this.previousTab = '';
  }
}

angular.module('heliApp').directive("headerPage", downgradeComponent({ component: HeaderComponent }) as angular.IDirectiveFactory)